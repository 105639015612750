import BaseFormatter from './base'
import { PreviewItem } from '../../types'

/**
 * Prepare preview images
 */
export default class PreviewFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  async format(data: { [key: string]: any }, report: { [key: string]: any }): Promise<void> {
    this.formatPreview(data, report, 'visualization', 'renderedImages', 'preview')
    this.formatPreview(data, report, 'url-render', 'renderResults', 'urlPreview')
  }

  formatPreview(
    data: { [key: string]: any },
    report: { [key: string]: any },
    resourceName: string,
    field: string,
    targetField: string
  ) {
    const resource = this.getResource(data, resourceName)
    const hasImages =
      resource &&
      Array.isArray(resource[field]) &&
      resource[field].length

    if (!hasImages) {
      return
    }

    const result: PreviewItem[] = []

    resource[field].forEach((itemData: {[key: string]: any}) => {
      const item: PreviewItem = {
        base64: itemData.base64,
        mediaType: 'image/png'
      }

      result.push(item)
    })

    report[targetField] = result
  }
}
