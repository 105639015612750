import { useState, useEffect } from "react";

export type PageParam = {
  page: number;
  pageSize?: number;
  dateFrom?: string
}

export function usePagedSource(
  fetchFn: ({ page, pageSize, dateFrom }: PageParam) => Promise<any>,
  page: number,
  pageSize?: number,
  dateFrom?: string
) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [data, setData] = useState<any>(undefined)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      setError(null)

      try {
        const res = await fetchFn({ page, pageSize, dateFrom })
        setData(res)
      } catch (error: any) {
        setError(error.message)
        setData(undefined)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [page, pageSize, fetchFn, dateFrom])

  return [data, error, loading]
}
