import TextInput, { TextInputType } from 'app/components/text-input';
import React from 'react'
import { Form } from 'react-bootstrap'

export type FormControlProps = {
	id?: string;
	labelClass?: string;
	label?: string | JSX.Element;
	name?: string;
	className?: string;
	parentClass?: string;
	type?: TextInputType;
	value?: string | number;
	onChange?: React.ChangeEventHandler;
	required?: boolean;
	autoComplete?: string;
	error?: string;
	placeholder?: string;
	readOnly?: boolean;
  note?: string
  control?: JSX.Element
  disabled?: boolean
}

export const FormControl: React.FC<React.PropsWithChildren<FormControlProps>> = (props: FormControlProps) => {
	const {
    id, label, labelClass, error, type, parentClass,
    note, className, control, ...others
  } = props;

  const groupClass = 'form-control-container ' + (parentClass || 'mb-4')

	return (
		<div className={groupClass}>
			{
        label && (
          <div className={'input-label ' + (labelClass || '')}>
            {label}
          </div>
        )
      }

      {control ? control : <TextInput type={type ?? 'text'} id={id} {...others} className={className}/>}
      {note ? <span className="text-muted"><small>{note}</small></span> : null}
			{error && (<div className="invalid-feedback d-block">{error}</div>)}
		</div>
	)
}
