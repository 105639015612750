import React, { useContext, useEffect, useState } from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { AuthContext } from 'app/modules/auth'
import { MainNotificationContext } from 'app/modules/shared'
import { GetBackAfterSignin } from 'app/modules/shared/routing/back-after-signin'
import { getPageData } from 'app/modules/shared/routing'
import { Button } from 'react-bootstrap'

export function ForbiddenPage({error, resetError}: {error?: Error, resetError: () => void}): JSX.Element | null {
  const { _t } = useTypedTranslation()
  const auth = useContext(AuthContext)
  const mainNotification = useContext(MainNotificationContext)
  const [component, setComponent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    if (!auth.user && error) {
      mainNotification.setMessageData(_t('signin-required-note'), 'info')
      const pageMatch = error.message.match(/pageKey:\s+([a-z0-9_-]+)/)
      const page = pageMatch && getPageData(pageMatch[1])

      if (page) {
        setComponent(<GetBackAfterSignin page={page}/>)
        return
      }
    }

    setComponent(
      <div className="container-fluid main-container">
        <div className="centered-layout error-page">
          <div className="error-code">403</div>
          <div className="error-message mb-4">{_t('forbidden-error')}</div>
          <Button variant="primary" onClick={resetError}>{_t('go-back')}</Button>
        </div>
      </div>
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return component
}
