import React, { createContext, useContext, useEffect, useState } from 'react';
import { ConfigurationService } from '../services/configuration.service';
import { ApplicationConfiguration } from '../types/application-configuration';
import { allowedFeaturesStorage } from '../../auth/helper/storage'
import { setConstants } from '../../reports/helpers/functions';
import { useTypedTranslation } from '../hooks/use-translation';
import { mainNotificationContext } from './main-notification-context';
import { HttpService } from 'app/modules/shared';
import { authHttpInterceptor } from 'app/modules/auth/interceptors';

export const applicationConfiguration = createContext<ApplicationConfiguration>({} as ApplicationConfiguration);

export const WithConfigurationContext = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element | null => {
  const notificationContext = useContext(mainNotificationContext)
  const { _t } = useTypedTranslation()
  const [configuration, setConfiguration] = useState<ApplicationConfiguration | undefined>()
  const [error, setError] = useState('')

  // Add http interceptors dealing with authentication
  HttpService.addRequestInterceptor(authHttpInterceptor);

  async function loadConfiguration() {
    try {
      const config = await ConfigurationService.getConfiguration();

      if (config.licenseExpiration !== null && config.licenseExpiration !== undefined && config.licenseExpiration <= 30) {
        notificationContext.setMessageData(
          config.licenseExpiration > 0 ? _t("license-expiration-message", { days: config.licenseExpiration }) : _t("license-expired-message"),
          config.licenseExpiration <= 7 ? 'danger' : 'warning', 15000
          )
      }

      // We should access allowed features in routing, where config context is not available.
      // So we save them to special storage.
      allowedFeaturesStorage.setFeatures(config.allowedFeatures)

      setConfiguration({ ...config, loadConfiguration });
      setConstants(config.constantStrings)
    } catch (error: any) {
      setError(error.message);
    }
  }

  useEffect(() => {
    loadConfiguration();
    // eslint-disable-next-line
  }, []);

  if (error) {
    return <div>{_t('Server under maintenance. Be back soon.')}</div>;
  }

  if (!configuration) {
    return null
  }

  return <applicationConfiguration.Provider value={configuration}>{children}</applicationConfiguration.Provider>;
};
