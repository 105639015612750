export enum ResourceTypes {
  File_hash_md5 = 'MD5',
  File_hash_sha1 = 'SHA1',
  File_hash_sha256 = 'SHA256',
  File_hash_sha512 = 'SHA512',
  Url = 'url',
  Ip = 'IP address',
  Domain = 'domain',
  Email = 'email',
  Uuid = 'UUID',
  Registry_path = 'registry path',
  Revision_save_id = 'revision save ID'
}
