import { createContext, useContext } from 'react'

interface ExportCtx {
  exporting: boolean;
  flowId: string;
  reportId: string;
  subpage: string;
}

const initialStatus: ExportCtx = {
  exporting: false,
  flowId: '',
  reportId: '',
  subpage: ''
}

export const ExportContext = createContext<ExportCtx>(initialStatus)

export const useExport = () => {
  const value = useContext<ExportCtx>(ExportContext)
	return value
}
