import Axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { HttpError } from '../exceptions'

const client = Axios.create();

export const HttpService = {
  /**
   * Add request interceptor to manipulate request config before request actually happens (Set auth headers for example)
   * @param interceptor Axios request interceptor
   */
  addRequestInterceptor(interceptor: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig): void {
    client.interceptors.request.use(interceptor);
  },

  /**
   * Add response interceptor to perform some actions on specific responses from backend
   */
  addResponseInterceptor(
    interceptor?: (response: AxiosResponse) => AxiosResponse,
    onError?: (error: any) => Promise<any>
  ): void {
    client.interceptors.response.use(interceptor, onError)
  },

  get<T = {[key: string]: any} | any[]>(url: string, config?: AxiosRequestConfig, withHeaders = false): Promise<T> {
    config = extendConfig(config)

    return client.get<T>(url, config).then((response: AxiosResponse) => {
      return withHeaders ? [response.data, response.headers, response.status] : response.data
    }).catch(processError)
  },

  post<T = {[key: string]: any}>(
    url: string, data?: {[key: string]: any}, config?: AxiosRequestConfig, callback?: CallableFunction
  ): Promise<T> {
    config = extendConfig(config)
    const promise = client.post<T>(url, data, config).then(processResponse).catch(processError)

    return withCallback(promise, callback)
  },

  put<T = {[key: string]: any}>(url: string, data?: {[key: string]: any}, config?: AxiosRequestConfig): Promise<T> {
    config = extendConfig(config)
    return client.put<T>(url, data, config).then(processResponse).catch(processError)
  },

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    config = extendConfig(config)
    return client.delete(url, config).then(processResponse).catch(processError)
  }
};

/**
 * Some custom actions with successfull response
 */
function processResponse(response: AxiosResponse) {
  return response.data
}

/**
 * Handle http request error
 */
async function processError(error: any) {
  const response = error.response
  let body: any = null
  if (typeof response?.data?.text === 'function') {
    body = JSON.parse(await response.data.text())
  } else {
    body = response?.data
  }

  throw new HttpError(response, response?.status, `${response?.status} ${body.detail ?? response?.statusText}`)
}

/**
 * Apply some default config
 */
function extendConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
  if (!config) {
    config = {}
  }

  // Connection timeout
  // config.signal = AbortSignal.timeout(15000)

  return config
}

/**
 * Add custom callback to request promise
 */
function withCallback(promise: Promise<any>, callback: CallableFunction | undefined) {
  if (callback) {
    promise = promise.then(callback as (value: any) => any)
  }

  return promise
}
