import mock from "../mock"

export const SearchResult =[
    {
        id: "ed01b87e-dd77-46ec-b384-3adf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-c5ab-477f-a1d0-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
    {
        id: "ed01b87e-dd77-45ec-b384-3adf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-c5ae-476f-a1d0-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
    {
        id: "ed01b87e-dd76-46ec-b384-3adf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-c5ae-457f-a1d0-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
    {
        id: "ed01b87e-dd77-46ec-b384-3aaf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-c5ae-277f-a1d0-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
    {
        id: "ed01b87e-dd77-46ec-b384-3cdf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-a5ae-477f-a1d0-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
    {
        id: "ed01b87e-dd27-46ec-b384-3adf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-c5ae-477f-a130-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
    {
        id: "ed01b87e-ad77-46ec-b384-3adf8cbd63cc",
        submissionId: "5fb40f1e4c185dc87bc3ccb0",
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: "malicious"
    },
    {
        id: "49ae97e9-c5ae-4773-a1d0-d1f3cf37f061",
        submissionId: "5fb40f554c185dc87bc3ccb1",
        file: {
            name: 'test.txt',
            mediaType: 'text/plain',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        verdict: "unknown",
        error: "Scan took too long and was interrupted"
    },
];

mock.onGet("/api/search/totalpage").reply(() => {
    return [200, {totalSize: SearchResult.length}]
});

const searchUri = "/api/search";
const url = new RegExp(`${searchUri}*`);
mock.onGet(url).reply((request) => {
    const pageNum = request.url ? request.url.split("?")[1] ? parseInt(request.url.split("?")[1].split("=")[1]) : 1 : 1;

    return [200, {totalSize: SearchResult.length, data: SearchResult.slice(pageNum, pageNum + 10)}]
});