import { useTypedTranslation } from "app/modules/shared/hooks/use-translation"
import React from "react"

// Content overlay if menu is expanded
export function MobileMenuOpenedOverlay({isOpened, onClick}: {isOpened: boolean, onClick?: CallableFunction}) {
  return (
    <div
      className={`mobile-menu-opened-overlay ${!isOpened ? 'd-none' : ''} hide-desktop`}
      onClick={() => onClick && onClick()}
    />
  )
}

// Close menu button on mobile only
export function MobileMenuCloseButton({isOpened, toggleMenu}: {isOpened: boolean, toggleMenu: CallableFunction}) {
  const { _t } = useTypedTranslation()

  return (
    <div className={`appbar-menu-close-mobile ${!isOpened ? 'd-none' : ''}`}>
      <button className="appbar-menu-close-button" onClick={() => toggleMenu()}>{_t('close')}</button>
    </div>
  )
}
