import React, { useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { UserButton } from '../../../../components/appbar/user-button/user-button';
import { LanguageSelector } from '../../../../components/appbar/language'
import { AdminFeature } from '../../types';
import { useFeature } from '../../hooks';
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { LogoImage } from 'app/components/logo';
import { LogoImageType } from '../../features/settings/appearence/types';
import { AuthContext } from 'app/modules/auth';
import Notifications from 'app/components/appbar/notifications/notifications';
import { getActiveRouteClass } from 'app/components/appbar/helpers/helpers';
import { getPageUrl } from 'app/modules/shared/routing';

interface FeatureData {
  name: string;
  pageKey: string;
  icon: string;
  skip?: boolean;
  feature: AdminFeature
}

export const AdminAppbar = (): JSX.Element => {
  return (
    <Navbar collapseOnSelect expand="md" className="appbar mb-2 has-sub-navbar">
      <Container fluid className="appbar-container">
      <Navbar.Brand href="scan">
        <LogoImage type={LogoImageType.TopMenu} className="logo"/>
      </Navbar.Brand>
      <div className="admin-panel-title">ADMIN PANEL</div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav">
        <i className="fas fa-bars appbar-toggler-icon" />
      </Navbar.Toggle>
      <Navbar.Collapse className="appbar-collapse" id="responsive-navbar-nav">
        <Nav className="h-100">
          <LeftMenu />
          <div className='my-auto appbar-divider' />
          <LanguageSelector />
          {/* <Notifications />  - To be added in phase 2*/}
          <UserButton />
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

function LeftMenu(): JSX.Element {
  const { _t } = useTypedTranslation()
  const { pathname: url } = useLocation()
  const { user } = useContext(AuthContext)
  const feature = useFeature()
  const moduleUrl = url.split('/')[1]

  const getActiveMenuClass = (url: string) => getActiveRouteClass([url], feature as string, 'appbar-item-selected')

  const features: FeatureData[] = [
    {
      name: _t('stat'),
      pageKey: 'admin-stat-home',
      icon: 'ds3-icon ds3-stat',
      feature: AdminFeature.Stat
    },
    {
      name: _t('user-management'),
      pageKey: 'admin-user-management-home',
      icon: 'ds3-icon ds3-user-group',
      skip: user?.isAuditor,
      feature: AdminFeature.UserManagement
    },
    {
      name: _t('jobs'),
      pageKey: 'admin-jobs-home',
      icon: 'ds3-icon ds3-server',
      feature: AdminFeature.Jobs
    },
    {
      name: _t('settings'),
      pageKey: 'admin-settings-home',
      icon: 'ds3-icon ds3-settings',
      skip: user?.isAuditor,
      feature: AdminFeature.Settings
    },
    {
      name: _t('scan-sources'),
      pageKey: 'admin-scan-sources-home',
      icon: 'ds3-icon ds3-laptop',
      skip: user?.isAuditor,
      feature: AdminFeature.ScanSources
    },
    {
      name: _t('audit-logger'),
      pageKey: 'admin-audit-logger-home',
      icon: 'ds3-icon ds3-search',
      feature: AdminFeature.AuditLogger
    }
  ];

  const links = features.map((featureLink, idx) => {
    if (featureLink.skip) {
      return null
    }

    const activeClass = getActiveMenuClass(featureLink.feature)

    return (
      <div key={featureLink.pageKey} className={`appbar-item-wrapper ${activeClass}`}>
        <Link className='appbar-item nav-link d-flex align-items-center' to={getPageUrl(featureLink.pageKey) as string}>
          <i className={`${featureLink.icon} me-2`}></i>
          <span>{featureLink.name}</span>
        </Link>
      </div>
    )
  })

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{links}</>
}
