import { TagType } from "./signal-group"

export const mainFileKey = 'mainFile'

export enum AllFilesTypes {
  MainFile = mainFileKey,
  ExtractedFiles = 'extractedFiles',
  DownloadedFiles = 'downloadedFiles',
  ContextFiles = 'contextFiles'
}

export enum AllExtractedFilesTypes {
  ExtractedFiles = 'extractedFiles',
  DownloadedFiles = 'downloadedFiles',
  ContextFiles = 'contextFiles'
}

export type AllExtractedFiles = {
  [key in AllExtractedFilesTypes]: ExtractedFile[]
}


export type ExtractedFile = {
  id: string,
  name: string,
  interesting: boolean,
  mediaType: string,
  description: string,
  private: boolean,
  hashes: {[key: string]: string},
  size: string,
  origin: string,
  meta?: {[key: string]: any},
  content?: string,
  tags?: TagType[],
  verdict?: {[key: string]: any},
  language?: string;
  source: AllExtractedFilesTypes
}

export type MainFileSummary = {
  id: string,
  name: string | undefined,
  description: string | undefined,
  size: string | undefined,
  mediaType: string | undefined,
  source: string
}
