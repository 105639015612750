import { inc } from 'app/modules/shared/helpers'
import { ReportFilterSection, ReportFilterSectionType } from '../../components/filter/types'
import { DisassemblyFilterStat, DisassemblySection } from '../../features/disassembly/types'
import BaseFormatter from './base'

/**
 * Format data needed for Disassembly Sections report subpage.
 */
export default class DisassemblyFormatter extends BaseFormatter {
  sectionsCache: {[key: string]: boolean} = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: {[key: string]: any}, report: {[key: string]: any}): void {
    this.sectionsCache = {}

    const resource = this.getResource(data, 'file')
    const hasData = (
      resource &&
      Array.isArray(resource.disassemblySections) &&
      resource.disassemblySections.length
    )
    const stat: DisassemblyFilterStat = {types: {lib: {}, api: {}}, total: 0}
    const filterSections: {[sectionName: string]: ReportFilterSection} = {
      search: {type: ReportFilterSectionType.Search, name: 'search', items: []},
      lib: {type: ReportFilterSectionType.Flags, name: 'lib', items: [], title: 'library'},
      api: {type: ReportFilterSectionType.Flags, name: 'api', items: []},
    }

    if (!hasData) {
      return
    }

    for (let i = 0; i < resource.disassemblySections.length; i++) {
      const section: DisassemblySection = resource.disassemblySections[i];
      this.gatherFilterSections(section, filterSections)
      this.gatherFilterStat(section, stat)
    }

    report.disassembly = {
      data: resource.disassemblySections,
      filter: {stat, sections: Object.values(filterSections)}
    }
  }

  gatherFilterStat(section: DisassemblySection, stat: DisassemblyFilterStat) {
    const statTypes = stat.types
    const apis = section.apis || []
    const libs = section.libs || []

    for (const api of apis) {
      statTypes.api[api] = inc(statTypes.api[api])
    }
    for (const lib of libs) {
      statTypes.lib[lib] = inc(statTypes.lib[lib])
    }

    stat.total = inc(stat.total)
  }

  gatherFilterSections(section: DisassemblySection, sections: {[sectionName: string]: ReportFilterSection}) {
    const apis = section.apis || []
    const libs = section.libs || []

    for (const api of apis) {
      if (typeof this.sectionsCache[api] === 'undefined') {
        sections.api.items.push({value: api, name: api})
        this.sectionsCache[api] = true
      }
    }
    for (const lib of libs) {
      if (typeof this.sectionsCache[lib] === 'undefined') {
        sections.lib.items.push({value: lib, name: lib})
        this.sectionsCache[lib] = true
      }
    }
  }
}
