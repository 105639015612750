import BaseFormatter from './base'
import {
  FileProgress,
  TaskProgress,
  ScanState
} from '../../types'
import { has } from '../../helpers/functions'

/**
 * Prepare information about scan progress
 */
export default class ProgressFormatter extends BaseFormatter {
  filterFields = [
    'overallState',
    'positionInQueue',
    'taskReference',
    'subtaskReferences',
    'taskWarnings'
  ]
  dashRegexp = /-/g
  underscoreRegexp = /_/g

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const mainTask = has(data, 'taskReference') ?
      this.buildTask(data.taskReference) :
      this.buildErroredMainTask()

    const progress: FileProgress = {
      state: this.formatState(data.overallState ?? ''),
      main: mainTask,
      subtasks: [],
      positionInQueue: data.positionInQueue,
      warnings: data.taskWarnings || []
    }

    const subtasks = typeof data.subtaskReferences !== 'undefined' ?
      data.subtaskReferences : []

    subtasks.forEach((task: any) => {
      const item = this.buildTask(task)
      item && progress.subtasks.push(item)
    })

    report.progress = progress
  }

  /**
   * Create fake data in case when there was an error and there's no main task data
   */
  buildErroredMainTask(): TaskProgress {
    return {
      name: this.formatTaskName('transform-file'),
      state: 'failed' as ScanState,
      duration: this.formatTaskDuration(0)
    }
  }

  /**
   * Build data for scan subtask
   *
   * @param task
   */
  buildTask(task: { [key: string]: any }): TaskProgress {
    return {
      name: this.formatTaskName(task.name),
      state: this.formatState(task.state),
      duration: this.formatTaskDuration(task.processTime)
    }
  }

  /**
   * Create human readable task name
   *
   * @param name
   */
  formatTaskName(name?: string): string {
    if (!name) return 'Unknown'

    const names: { [anyKey: string]: string } = {
      'transform-file': 'Analyzing submission',
      'visualization': 'Visualization',
      'osint': 'OSINT lookup',
      'domain-resolve': 'Domains resolving',
      'file-download': 'Downloading referenced files',
      'osint-ex': 'Extra OSINT lookup',
      'geolocation-lookup': 'IP geolocation lookup',
      'url-render': 'URL preview',
      'osint-fuzzyhash': 'FSIO Fuzzy hash blacklist lookup'
    }

    return typeof names[name] !== 'undefined' ? names[name] : name.replace(this.dashRegexp, ' ')
  }

  /**
   * Create human readable task state
   *
   * @param state
   */
  formatState(state?: string): ScanState {

    if (!state) return ScanState.Unknown

    state = state.replace(this.underscoreRegexp, ' ').toLowerCase()

    if (state === 'failed partial') {
      state = 'failed'
    } else if (state === 'success partial') {
      state = 'success'
    }

    return state as ScanState
  }

  /**
   * Create human readable task duration
   *
   * @param duration
   */
  formatTaskDuration(duration: number): { minutes: number; seconds: number; } {
    duration = Math.floor(duration ?? 0)
    const result = { 'minutes': 0, 'seconds': 0 }

    const seconds = Math.floor(duration / 1000)
    if (seconds < 60) {
      result.seconds = seconds
      return result
    }

    result.minutes = Math.floor(seconds / 60)
    result.seconds = seconds % 60

    return result
  }
}
