import React, { createContext } from 'react';
import { Cache } from './cache';

const cache = new Cache({
  maxAge: 3600000,
  maxItems: 1000
});

export const CacheContext = createContext<Cache>(cache);
export const WithCache: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <CacheContext.Provider value={cache}>
      {children}
    </CacheContext.Provider>
  )
}
