import BaseFormatter from './base'
import { has } from '../../helpers/functions'
import { SignalVerdict } from '../../types'

/**
 * Format geolocation data
 */
export default class GeolocationFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: {[key: string]: any}, report: {[key: string]: any}): void {
    const resource = this.getGeolocation(data)
    if (!resource) {
      return
    }

    const geolocation = resource.domainResolveResults
    if (!geolocation || !geolocation.length) {
      return
    }

    const osint = report.osint
    this.getDomainsVerdicts(geolocation, osint)

    report.geolocation = geolocation
  }

  /**
   * Detect, if any of geolocated IPs contain malicious domains
   */
  getDomainsVerdicts(geolocation: any[], osint: {[key: string]: any}): void {
    const domains = this.extractOsintDomains(osint)
    const cache: {[key: string]: any} = {}

    domains.forEach(typeDomains => {
      typeDomains.forEach((item: {[key: string]: any}) => {
        const key = item.resource
        if (!cache[key]) {
          cache[key] = []
        }

        cache[key].push({...item.verdict, provider: item.provider})
      })
    })

    geolocation.forEach(item => {
      const resource = item.resource.data
      const cacheIndex = Object.keys(cache).findIndex((domain: string) => domain === resource)
      if (cacheIndex < 0) {
        return
      }

      item.verdicts = cache[Object.keys(cache)[cacheIndex]]
    })
  }

  /**
   * Extract only domain from OSINT data
   */
  extractOsintDomains(osint: {[key: string]: any}): any[] {
    const domains: any[] = []

    Object.keys(SignalVerdict).forEach(verdictKey => {
      const verdict = SignalVerdict[verdictKey as keyof typeof SignalVerdict]
      const hasDomains = has(osint, 'groups', verdict, 'domain')

      if (hasDomains) {
        domains.push(osint.groups[verdict].domain)
      }
    })

    return domains
  }
}
