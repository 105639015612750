import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation';
import { useLocation } from 'react-router';
import AppbarDropdown from './appbar-dropdown';
import { getActiveRouteClass } from './helpers/helpers';
import { getPageUrl } from 'app/modules/shared/routing';
import { Link } from 'react-router-dom';

const Feed = () : JSX.Element => {
  const { _t } = useTypedTranslation()
  const currentRoute = useLocation().pathname.substring(1)
  // Get class for the menu items
  const getActiveMenuClass = (activeRoutes: string[]) => getActiveRouteClass(activeRoutes, currentRoute, 'appbar-dropdown-item-selected')

  return (
    <AppbarDropdown
      // Get class for the dropdown label
      className={getActiveRouteClass(['feed', 'archives'], currentRoute, 'appbar-dropdown-selected')}
      title={_t('feed')}
    >
      <Link className="appbar-dropdown-menu-item dropdown-item" to={getPageUrl('feed') as string}>{_t('latest-xml')}</Link>
      <NavDropdown.Divider className="hide-desktop m-0" />
      <Link className="appbar-dropdown-menu-item dropdown-item" to={getPageUrl('archives') as string}>{_t('daily-archives')}</Link>
    </AppbarDropdown>
  )
}

export default Feed
