import BaseFormatter from './base'
import { hasFields } from '../../helpers'

/**
 * Format report response data for MS Office file details page
 */
export default class ApkDetailsFormatter extends BaseFormatter {
	/**
	 * Format original report data
	 *
	 * @param data
	 * @param report
	 */
	format(data: { [key: string]: any }, report: { [key: string]: any }): void {
		const result: { [key: string]: any } = {}

		const resource = this.getResource(data, 'file')
		if (!resource) return

		const fileType = (!data?.file?.type || data.file.type === 'other') ?
			this.getFileType(resource) : data.file.type
		if (fileType !== 'apk') {
			return
		}

		this.extractOverview(resource, result);
		this.extractActivities(resource, result);
		this.extractManifest(resource, result);
		this.extractReceivers(resource, result);
		this.extractServices(resource, result);
		this.extractSigners(resource, result);
		this.extractActivities(resource, result);
		if (resource.apkMetaData) {
			this.extractMetaInfo(resource.apkMetaData, result);
		}

		if (hasFields(result)) {
			report.apk_details = result
			report.apk_details.overview = { ...report.details.overview, ...report.apk_details.overview }
			delete report.details
		}
	}

	/**
	 * Gather data to display in overview block
	 *
	 * @param data
	 * @param result
	 */
	extractOverview(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		const overview: { [key: string]: any } = {}

		const base64 = this.extractIcon(resource);
		if (base64) {
			overview.icon = base64;
		}

		if (hasFields(overview)) {
			result.overview = overview
		}
	}

	extractIcon(resource: { [key: string]: any }): string | null {
		if (Array.isArray(resource.apkIcons) && resource.apkIcons.length) {
			const icon = resource.apkIcons[0];
			if (Array.isArray(icon.data)) {
				const CHUNK_SZ = 0x4000;
				const chunk: string[] = [];
				for (let i = 0; i < icon.data.length; i += CHUNK_SZ) {
					chunk.push(String.fromCharCode(
						...icon.data.slice(i, i + CHUNK_SZ).map((n: number) => n >= 0 ? n : 256 + n)
					));
				}
				return btoa(chunk.join(""));
			}
		}

		return null;
	}

	extractActivities(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		if (!Array.isArray(resource.apkActivities)) {
			return;
		}

		result.activities = {};
		if (resource.apkMainActivity) {
			result.activities.mainActivity = resource.apkMainActivity;
		}
		result.activities.activities = [...resource.apkActivities].sort(
			(a: string, b: string) => a > b ? 1 : a < b ? -1 : 0
		);
	}

	extractManifest(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		if (resource.apkManifestXml) {
			result.manifest = resource.apkManifestXml;
		}
	}

	extractSigners(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		if (!Array.isArray(resource.apkSigners)) return;

		const signers: { [key: string]: any }[] = [];
		for (const item of resource.apkSigners) {
			if (item.path && Array.isArray(item.certificateMetas)) {
				signers.push({
					path: item.path,
					certificateMeta: {
						startDate: item.certificateMetas[0].startDate,
						endDate: item.certificateMetas[0].endDate,
						algorithm: item.certificateMetas[0].signAlgorithm
					}
				});
			}
		}

		result.signers = signers;
	}

	extractServices(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		if (!Array.isArray(resource.apkServices)) return;

		const services: { [key: string]: any }[] = [];
		for (const item of resource.apkServices) {
			services.push({
				packageName: item.packageName,
				interesting: item.interesting,
				intentFilters: [...item.intentFilters]
			})
		}

		result.services = services.sort((a, b) => {
			let res = (b.interesting ?? 0) - (a.interesting ?? 0);
			if (res === 0) {
				res = (b.packageName < a.packageName) ? 1 : -1;
			}

			return res;
		});
	}

	extractReceivers(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		if (!Array.isArray(resource.apkReceivers)) return;

		const receivers: { [key: string]: any }[] = [];
		for (const item of resource.apkReceivers) {
			receivers.push({
				packageName: item.packageName,
				interesting: item.interesting,
				metaData: item.metaData,
				intentFilters: [...item.intentFilters]
			})
		}

		result.receivers = receivers.sort((a, b) => {
			let res = (b.interesting ?? 0) - (a.interesting ?? 0);
			if (res === 0) {
				res = (b.packageName < a.packageName) ? 1 : -1;
			}

			return res;
		});
	}

	extractMetaInfo(resource: { [key: string]: any }, result: { [key: string]: any }): void {
		if (Array.isArray(resource.usesPermissions)) {
			result.usesPermissions = resource.usesPermissions.map(perm => ({
				name: perm.name,
				interesting: perm.interesting,
				metaData: perm.metaData
			})).sort((a, b) => {
				let res = (b.interesting ?? 0) - (a.interesting ?? 0);
				if (res === 0) {
					res = (b.name < a.name) ? 1 : -1;
				}
	
				return res;
			});
		}

		if (Array.isArray(resource.usesFeatures)) {
			result.usesFeatures = resource.usesFeatures.map(feat => ({
				name: feat.name,
				required: feat.required
			})).sort((a, b) => {
				let res = (b.required ?? false) - (a.required ?? false);
				if (res === 0) {
					res = (b.name < a.name) ? 1 : -1;
				}
	
				return res;
			})
		}

		const keys = [
			'compileSdkVersion', 'glEsVersion', 'minSdkVersion', 'packageName', 'targetSdkVersion',
			'isFeatureSplit', 'isSplitRequired', 'isolatedSplits', 'label', 'largeScreens', 'smallScreens'
		]

		const meta: { [key: string]: any } = {};
		for (const key of keys) {
			if (key in resource) {
				meta[key] = resource[key];
			}
		}

		if (hasFields(meta)) {
			result.meta = meta;
		}
	}
}
