import { SignalVerdict } from './signal'
import { TagType } from '.'

export enum SearchResultsUnionMethod {
  And = 'and',
  Or = 'or'
}

export interface SearchResultData {
  items: any[]
  count: number
  count_search_params?: number
  method: SearchResultsUnionMethod,
  dbs_sync?: boolean
}

export interface SourceFileData {
  name: string
  mime_type: string
  short_type: string
  sha256: string
  link: string | null
}

export interface FieldTypeMatch {
  value: string,
  exact?: boolean
}

export interface OriginMatches {
  origin: {
    sha256: string,
    filetype: string,
    mime_type: string,
    relation: string
  }
  matches: {
    [field_type: string]: FieldTypeMatch[]
  }
  has_non_exact_matches?: boolean
}

export interface GroupedReportItemType {
  id: string
  scan_init: {
    id: string,
    job?: { [key: string]: any }
  }
  state: string
  verdict: SignalVerdict
  original?: SignalVerdict
  date: string
  tags: TagType[]
  matches?: OriginMatches[],
  retry_count?: number,
  updated_date?: string,
  is_source_archive?: boolean,
  score?: number
}

export interface WithFileType {
  file: SourceFileData
}

export interface ReportItemType extends GroupedReportItemType {
  file: SourceFileData
}

export interface GroupedReportType {
  file: SourceFileData,
  reports: GroupedReportItemType[]
}


export interface InterestingReportType extends WithFileType {
  id: string,
  flowId: string,
  score: number,
  verdict: SignalVerdict
}

export interface InterestingReportsType {
  [key: string]: InterestingReportType
}

export interface SearchParams {
  filename?: string
  filetype?: string
  media_type?: string
  verdict?: string
  verdict_group?: string
  filedesc?: string
  tag?: string
  tags?: string
  yara_rule?: string
  date_from?: string
  date_to?: string
  rate_from?: string
  rate_to?: string
  host?: string
  domain?: string
  imphash?: string
  ssdeep?: string
  authentihash?: string
  fuzzyfsiohash?: string
  source_sha256?: string
  source_md5?: string
  ip?: string | string[]
  url?: string
  uuid?: string
  registry_path?: string
  revision_save_id?: string
  sha1?: string
  sha256?: string
  sha512?: string
  md5?: string
  unique_files?: boolean,
  method?: string,
  signal_groups?: string,
  retry_count?: string,
  mitre_techniques?: string,
  source_type?: string
  query?: string
  country?: string
  derived_files?: boolean
}

export enum SearchType {
  Simple = 'simple',
  Advanced = 'advanced'
}
