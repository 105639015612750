import React, { useState, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Overlay, Tooltip } from 'react-bootstrap'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import Button from 'app/modules/shared/components/button'
import { Placement } from 'react-bootstrap/esm/types'
/**
 * Show icon to copy text some clipboard
 */
type Copy2ClipboardProps = {
  copyText: string;
  className?: string;
  title?: string;
  button?: string;
  style?: {[key: string]: string}
  tooltipPlacement?: Placement
  onCopy?: CallableFunction
}
export function Copy2Clipboard(
  { copyText, className, title, button, style, onCopy, tooltipPlacement = 'right' }: Copy2ClipboardProps
): JSX.Element {
  const { _t } = useTypedTranslation()
  const [show, setShow] = useState(false)
  const target = useRef(null);

  if (className === undefined) {
    className = ''
  }
  if (title === undefined) {
    title = _t('copy-to-clipboard')
  }

  const handleCopy = () => {
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 500)
    onCopy && onCopy()
  }

  return (
    <>
      <CopyToClipboard text={copyText} onCopy={() => handleCopy()} aria-label='Copy to clipboard'>
        {button ? (
          <Button variant='primary' ref={target} style={style}>{button}</Button>
        ) : (
          <i
            className={`ds3-icon ds3-copy icon-button icon-copy align-middle ${className}`}
            ref={target}
            onClick={e => e.stopPropagation()}
            aria-hidden={true}
            style={style}
          />
        )}
      </CopyToClipboard>
      <Overlay target={target.current} show={show} placement={tooltipPlacement}>
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {_t('copied')}
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
