import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routeState } from '../../../shared/context/route-state-context'

import styles from './navbar.module.scss';
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'

export const AdminFeatureNavbar = (): JSX.Element => {
  const { _t } = useTypedTranslation()
  const { pathname: url } = useLocation()
  const parts = url.split('/')
  const selectedSection = parts.pop()
  const baseUrl = parts.join('/')
  const pageState = useContext(routeState)

  const links = pageState.data.adminPageSections?.map((section, idx) => {
    const isActive = section.path === selectedSection
    const activeClass = isActive ? 'active text-m' : 'text-muted';
    const sectionUrl = baseUrl + '/' + section.path

    return (
      <Link to={sectionUrl} className={`${activeClass} py-2 me-4`} key={idx}>
        {_t(section.label.toLowerCase().replace(/ /g, '-'))}
      </Link>
    );
  });

  return (
    <div className="border-bottom border-top admin-submenu">
      <div className="admin-top-menu-links-container">
        <ul className={`${styles.tabbar} d-flex m-0 p-0 justify-content-center`}>{links}</ul>
      </div>
    </div>
  );
};
