import { ApplicationConfiguration } from '../../../modules/shared/types'
import { AuthContextData } from '../context'

/**
 * Perform signout
 */
export async function doSignout(authHandler: AuthContextData, config: ApplicationConfiguration) {
  await authHandler.signout()
  await config.loadConfiguration()
}
