/**
 * Retrieves the value of a cookie by its key
 * 
 * @param {string} key - The key of the cookie to retrieve
 * @returns {string} The value of the cookie, or an undefined if not found
 */
export const getCookie = (key: string): string | undefined => {
  // Use a RegExp constructor to avoid potential issues with special characters in the key
  const regex = new RegExp(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  const match = document.cookie.match(regex);

  // Use optional chaining to safely retrieve the last match
  return match?.pop();
}

/**
 * Retrieves a boolean value indicating whether a consent is accepted.
 * Compatible with cookieyes-consent tool.
 * 
 * @param {string} cookieName - The name of the cookie to get the consent from
 * @param {string} key - The key of the consent to retrieve (e.g., 'analytics')
 * @returns {boolean} True if the consent is 'yes', false otherwise
 */
export const getCookieConsent = (cookieName: string, key: string): boolean => {
  const cookieValue = getCookie(cookieName);

  if (!cookieValue) {
    return false;
  }
  
  const consents = cookieValue.split(',').map((consent) => {
    const [key, value] = consent.split(':');
    return { [key]: value === 'yes' ? true : value };
  });
  
  const consentAccepted = consents.find((consent) => consent[key] === true);
  return consentAccepted !== undefined;
}