import React, { createContext, useState } from 'react'
import { ModalState } from '../types'

export const modalContext = createContext(false as any)

/**
 * Context for toggling modal
 */
export function WithModalContext({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const [modalState, setModalState] = useState<ModalState>(new ModalState())
  const provided = {state: modalState, setState: setModalState}

  return (
    <modalContext.Provider value={provided}>
      {children}
    </modalContext.Provider>
  )
}
