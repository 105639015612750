import React, { useCallback, useContext, useState, MouseEventHandler, useEffect } from 'react';
import { OverlayTrigger, Popover, ButtonGroup, Dropdown } from 'react-bootstrap';
import { AuthContext } from '../context';
import { Trans } from 'react-i18next';
import { SigninLink } from './auth-link';
import { ButtonVariant } from 'app/modules/shared/components/types';
import Button from 'app/modules/shared/components/button';

export interface AuthButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  itemSelect?: (key: string | null) => void;
  groupLabel?: string | JSX.Element;
  userRole?: number;
  delay?: number;
  onClick?: MouseEventHandler<HTMLElement>;
  variant?: ButtonVariant;
  isPrivate: boolean;
  privateMsg: string;
  fileSize?: string | number;
  sizeMsg?: string;
  className?: string;
  disabled?: boolean;
  children?: JSX.Element | string | (string|JSX.Element)[];
}

export function AuthButton(props: AuthButtonProps): React.ReactElement {
  const {
    itemSelect,
    groupLabel,
    delay,
    onClick,
    variant,
    style,
    isPrivate,
    privateMsg,
    fileSize,
    sizeMsg,
    disabled,
    className,
  } = props;
  const [show, setShow] = useState(false)
  const [triggeredState, setTriggeredState] = useState(false)
  const [triggeredStep, setTriggeredStep] = useState<Date>()
  const user = useContext(AuthContext)?.user

  const signinMsg = (
    <Trans i18nKey="sign-in-first">
      You need to <SigninLink>sign in</SigninLink> first.
    </Trans>
  )

  const handleToggle = useCallback((doShow: boolean) => {
    setTriggeredState(doShow)
    setTriggeredStep(new Date())
  }, []);

  // Open or show popup depending on user click on button.
  // Auto hide popup after some timeout after showing.
  useEffect(() => {
    // Use timeout to avoid closing popup immediately due to outside click hindler
    setTimeout(() => {
      setShow(triggeredState);
    }, 10)

    let closeTimeout: NodeJS.Timeout
    if (triggeredState) {
      closeTimeout = setTimeout(() => {
        setShow(false);
      }, delay ?? 2000);
    }

    return () => closeTimeout && clearTimeout(closeTimeout)
  }, [delay, triggeredState, triggeredStep])

  // Close popup on outside click
  useEffect(() => {
    function action() {
      show && setShow(false)
    }

    document.body.addEventListener('click', action)
    return () => document.body.removeEventListener('click', action)
  }, [show])

  if (!user || isPrivate || (!fileSize && sizeMsg)) {
    const msg = !user ? signinMsg : (isPrivate ? privateMsg : sizeMsg)

    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        onToggle={handleToggle}
        show={show}
        overlay={(
          <Popover id="popover-basic">
            <Popover.Body>{msg}</Popover.Body>
          </Popover>
        )}
      >
        <div className='d-inline-block' style={{cursor: 'pointer'}}> {/* This container is needed to trigger click even on disabled button */}
          {itemSelect ? (
            <Dropdown className={className || ''} style={style as any}>
              <Button variant={variant} disabled={true}>{groupLabel}</Button>
              {props.children}
            </Dropdown>
          ) : (
            <Button
              variant={variant}
              className={className || ''}
              style={style as any}
              disabled={true}
            >
              {props.children}
            </Button>
          )}
        </div>
      </OverlayTrigger>
    )
  }

  return itemSelect ? (
    <Dropdown className={className || ''} style={style as any} onSelect={itemSelect}>
      <Dropdown.Toggle variant='tertiary' disabled={disabled}>
        {groupLabel}
      </Dropdown.Toggle>
      {props.children}
    </Dropdown>
  ) : (
    <Button
      variant={variant}
      className={className}
      onClick={onClick}
      style={style as any}
      disabled={disabled}
    >
      {props.children}
    </Button>
  )
}

AuthButton.defaultProps = {
  group: false
}
