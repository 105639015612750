import { allowedFeaturesStorage } from '../../auth/helper/storage'
import { UserRole, User } from '../../auth/types'
import { PageData } from './types'
import { logger } from 'app/components'

/**
 * Check if user can access given feature
 */
export function checkFeature(page: PageData) {
  const allowedFeatures = allowedFeaturesStorage.getFeatures()
  if (!allowedFeatures) {
    return true
  }

  const allowed = allowedFeatures.indexOf(page.feature as string) !== -1
  logger.debug('Check feature access: ', allowed, page.feature, allowedFeatures)

  return allowed
}

/**
 * Check if user can access page with given auth requirements
 */
export function checkAuth(user: User | undefined, auth: UserRole) {
  const valid = (
    (auth === UserRole.Any) ||
    (auth === UserRole.Guest && !user) ||
    ([UserRole.Admin, UserRole.Auditor].includes(auth) && user?.isAdmin) ||
    (auth === UserRole.Auditor && user?.isAuditor) ||
    (auth === UserRole.User && user)
  )

  return valid
}
