import {
  IOCDataTypes,
  IOCFileTypes,
  IOCGroupedByFileType,
  IOCGroupedByDataType,
  IOCItem,
  IOCFinalGroup,
  OsintFormattedData,
  OsintItem,
  OsintGroupKey,
  SignalVerdict
} from '../../types'
import BaseFormatter from './base'

type OsintCachedVerdict = {
  verdict: {
    code: string,
    strength: number
  },
  provider: string
}

/**
 * Merge osint verdicts into IOCs
 * This formatter should be launched after formatters for IOC and OSINT.
 */
export default class MergeIOCOsintVerdictFormatter extends BaseFormatter {
  cache: {[key: string]: OsintCachedVerdict[]} = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: {[key: string]: any}, report: {[key: string]: any}): void {
    const skip =
      !report.osint?.groups ||
      !Object.keys(report.osint.groups).length ||
      !report.ioc

    if (skip) {
      return
    }

    this.cacheOsint(report.osint)
    this.mergeVerdicts(report.ioc.data)
  }

  /**
   * Cache OSINT results for fast access to each item verdicts
   */
  cacheOsint(data: OsintFormattedData): void {
    Object.keys(data.groups || []).forEach((key: string): void => {
      const group: {[key: string]: OsintItem[]} | undefined = data.groups && data.groups[key as OsintGroupKey]
      if (!group) {
        return
      }

      Object.keys(group).forEach((type: string): void => {
        const items: OsintItem[] = group[type]

        items.forEach((item: OsintItem): void => {
          const resource = item.resource
          if (!this.cache[resource]) {
            const cache: OsintCachedVerdict[] = []
            this.cache[resource] = cache
          }

          this.cache[resource].push({
            verdict: item.verdict,
            provider: item.provider
          })
        })
      })
    })
  }

  /**
   * Add cached verdicts to IOCs
   */
  mergeVerdicts(iocs: IOCGroupedByFileType): void {
    if (iocs[IOCFileTypes.Main]) {
      this.mergeByTypeGroups(iocs[IOCFileTypes.Main])
    }
  }

  /**
   * Add cached verdicts to all IOCs items, grouped by type
   */
  mergeByTypeGroups(typeGroups: IOCGroupedByDataType): void {
    Object.keys(typeGroups).forEach((type: string): void => {
      const typeGroup: IOCFinalGroup[] | undefined = typeGroups[type as IOCDataTypes]

      // Just to pass TS type check
      if (!typeGroup) {
        return
      }

      typeGroup.forEach((originsGroup: IOCFinalGroup): void => {
        originsGroup.references.forEach((item: IOCItem): void => {
          const resource = item.data

          if (this.cache[resource]) {
            item.osintVerdicts = this.normalizeOsintVerdicts(this.cache[resource])
            originsGroup.hasOsintVerdicts = true
          }
        })
      })
    })
  }

  normalizeOsintVerdicts(osintVerdicts: OsintCachedVerdict[]) {
    if (!osintVerdicts.length) {
      return [{verdict: {code: SignalVerdict.Unknown}}]
    }

    return osintVerdicts.reduce((currentVerdicts: any[], osintVerdict, index) =>  {
      // Always add first verdict
      if (index === 0) {
        return [osintVerdict]
      }

      // If another provider had the same verdict, group results
      const similarIndex = currentVerdicts.findIndex(verdict => verdict?.provider && verdict?.verdict.code === osintVerdict?.verdict.code)
      const providers = currentVerdicts[similarIndex]?.provider || ''

      if(similarIndex >= 0 && !providers.includes(osintVerdict.provider)) {
        const providersString = providers ? `${providers}, ` : undefined
        currentVerdicts[similarIndex].provider = `${providersString}${osintVerdict.provider}`
        return currentVerdicts
      }

      // If we have a new verdict, remove previous unknown verdict
      const unknownIndex = currentVerdicts.findIndex(prevVerdict => prevVerdict?.verdict.code === SignalVerdict.Unknown)

      if (index > 0 && osintVerdict?.verdict.code !== SignalVerdict.Unknown && !providers.includes(osintVerdict.provider)) {
        unknownIndex >= 0 && currentVerdicts.splice(unknownIndex, 1)
        return [...currentVerdicts, osintVerdict]
      }

      return currentVerdicts
    }, [])
  }
}
