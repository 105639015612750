import mock from "../mock"

export const SubmissionReport = [
    {
        id: '5fb412964c185dc87bc3ccb4',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'test.zip',
            mediaType: 'application/zip',
            hash: 'a4f787c0765b7ac0bd031d60f18e61ff2d57b2d56b7c96802846e5dcd778c078'
        },
        reports: [
            {
                file: {
                    name: 'foo.doc',
                    mediaType: 'application/msword',
                    hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
                },
                verdict: 'malicious'
            },
            {
                file: {
                    name: 'bar.exe',
                    mediaType: 'application/x-executable',
                    hash: '2180f4a13add5e346e8cf6994876a9d2f5eac3fcb695db8569537010d24cd6d5'
                },
                verdict: 'suspicious'
            },
        ]
    },
    {
        id: '5fb4102a4c185dc87bc3ccb2',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c185dc87bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c185dc87bc3ca33',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccb4',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c1853c87bc3cab3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccb5',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c185da87bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccb7',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410d04c185dc87bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccb9',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb210e04c185dc87bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3cca3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e07c185dc87bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccf2',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c185d687bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccc3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c185dc876c3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    },
    {
        id: '5fb4102a4c185dc87bc3ccd2',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'BEPC.doc',
            mediaType: 'application/msword',
            hash: '57ffaa5ecaeee81afcd1b0479ef9fc96919653c502298262ff0c057ee8f3919e'
        },
        verdict: 'malicious'
    },
    {
        id: '5fb410e04c185dc97bc3ccb3',
        submitDate: '2021-04-23T18:25:43.511Z',
        file: {
            name: 'emotet.doc',
            mediaType: 'application/msword',
            hash: 'f3eb9ee50a95310326727bc131f94ef75901173261577be71f142fd8ae7f0908'
        },
        verdict: 'unknown',
        error: 'Scan took too long and was interrupted'
    }
  ];

mock.onGet("/api/submissions/totalpage").reply(() => {
    return [200, {totalSize: SubmissionReport.length}]
});

const submissionUri = "/api/submissions";
const url = new RegExp(`${submissionUri}*`);
mock.onGet(url).reply((request) => {
    const pageNum = request.url ? request.url.split("?")[1] ? parseInt(request.url.split("?")[1].split("=")[1]) : 1 : 1;

    return [200, {totalSize: SubmissionReport.length, data: SubmissionReport.slice(pageNum, pageNum + 10)}]
});