import React, { forwardRef, memo, useMemo } from 'react';
import {
  FixedSizeList as List,
  ListChildComponentProps,
  ListOnItemsRenderedProps,
  ListOnScrollProps
} from 'react-window';

import { HexViewerBodyChildren } from './types';
import HexViewerBodyRow from './hex-viewer-body-row';

export interface HexViewerBodyProps {
  children?: HexViewerBodyChildren,
  className?: string,
  height: number,
  itemRenderer?: React.ComponentType<React.PropsWithChildren<ListChildComponentProps>>,
  onItemsRendered: (props: ListOnItemsRenderedProps) => any,
  onScroll?: (props: ListOnScrollProps) => any,
  overscanCount: number,
  rowCount: number,
  rowHeight: number,
  rows: number,
  style?: React.CSSProperties,
  width: number,
}

const HexViewerBody: React.ForwardRefRenderFunction<List, HexViewerBodyProps> = ({
  children: bodyChildren,
  className,
  height,
  itemRenderer = HexViewerBodyRow,
  onItemsRendered,
  onScroll,
  overscanCount,
  rowCount,
  rowHeight,
  style,
  width,
}, ref: React.Ref<List>) => {
  //TODO test this!!!
  const innerElementType = useMemo(() => {
    return forwardRef(({
      children: listChildren,
      ...props
    }, ref: React.Ref<JSX.Element>) => (
      <div ref={ref} {...props}>
        {listChildren}
        {bodyChildren && (typeof bodyChildren === 'function' ? bodyChildren() : bodyChildren)}
      </div>
    )) as JSX.ElementType;
  }, [bodyChildren])

  return (
    <List
      className={className}
      height={height}
      innerElementType={innerElementType}
      itemCount={rowCount}
      itemSize={rowHeight}
      layout="vertical"
      onItemsRendered={onItemsRendered}
      onScroll={onScroll}
      overscanCount={overscanCount}
      ref={ref}
      style={{ ...style, overflowY: 'scroll' }}
      width={width}
    >
      {itemRenderer as any}
    </List>
  );
};

export default memo(forwardRef(HexViewerBody));
