import { listToObj, objectHasOneOfKeys } from "app/modules/shared/helpers";
import { ReportFilterValues } from "../../components/filter/types";
import { ExtractedString, ExtractedStringsGroup } from "../../types";
import { has } from "../../helpers";

/**
 * Check if extracted string is interesting (so might contain threat)
 */
export function isStringInteresting(string: ExtractedString): boolean {
  if (string.interesting) {
    return true
  }

  const meta = string.metaData
  if (meta?.apiref) {
    for (let i = 0; i < meta.apiref.length; i++) {
      if (meta.apiref[i].suspicious) {
        return true
      }
    }
  }

  return false
}

// Apply filter to all extracted strings
export function applyFilter(strings: ExtractedString[], filter: ReportFilterValues | undefined) {
  if (!filter) {
    return strings
  }

  const result: ExtractedString[] = []
  const typesFilter = listToObj(filter.type)
  const originFilter = listToObj(filter.origin)
  const search = filter.search?.[0]?.toLocaleLowerCase() as unknown as string

  for (const item of strings) {
    if (matchesFilter(item, typesFilter, originFilter, search)) {
      result.push(item)
    }
  }

  return result
}

// Check if string matches filter
export function matchesFilter(
  string: ExtractedString,
  typesFilter: {[type: string]: boolean},
  originFilter: {[origin: string]: boolean},
  search: string | undefined
) {
  return (
    (!has(typesFilter, 'interesting') || isStringInteresting(string)) &&
    (!has(typesFilter, 'apiRef') || string.metaData?.apiref) &&
    (!has(typesFilter, 'signal') || string.metaData?.triggeredConsumerIDs) &&
    (!has(typesFilter, 'utf8') || string.metaData?.encoding === 'UTF8') &&
    (!Object.keys(originFilter).length || has(originFilter, string.origin)) &&
    (!search || string.str.toLocaleLowerCase().includes(search))
  )
}
