import { SignalType, SignalVerdict, SignalStrength, signalStrengthList, UniqueSignalVerdict } from '../types'
import { has } from './functions'

/**
 * Get verdicts that describe more or less significant threat level
 */
export function getThreatVerdicts(): string[] {
  return [
    SignalVerdict.Suspicious,
    SignalVerdict.Likely_malicious,
    SignalVerdict.Malicious
  ]
}

/**
 * Get verdicts that describe no threat found
 */
export function getNoThreatVerdicts(): string[] {
  return [
    SignalVerdict.Benign,
    SignalVerdict.Unknown
  ]
}

export function getVerdictLabel(name: SignalVerdict, passBenign = false): string {
  switch (name) {
    case SignalVerdict.Benign:
      if (passBenign) {
        return name as string
      } else {
        return 'benign'
      }
    case SignalVerdict.Informational:
      return 'no threat'
    default:
      return name as string
  }
}

/**
 * Cast numeric strength value to verdict, in case when it might not exactly match verdict
 */
export function castAverageStrengthToVerdict(strength: number, mergeSimilar = false): SignalVerdict {
  for (let i = 0; i < signalStrengthList.length; i++) {
    const value = signalStrengthList[i] as number

    if (strength <= value) {
      return castStrengthToVerdict(value, mergeSimilar)
    }
  }

  return SignalVerdict.Malicious
}

/**
 * Cast numeric strength value to verdict, in case when we know that it exactly matches verdict
 */
export function castStrengthToVerdict(strength: number, mergeSimilar = false): SignalVerdict {
  if (strength < 0) {
    return SignalVerdict.Benign
  } else if (strength === 0) {
    return SignalVerdict.Unknown
  } else if (strength <= 0.25) {
    return SignalVerdict.No_threat
  } else if (strength <= 0.5) {
    return SignalVerdict.Suspicious
  } else if (strength <= 0.75) {
    return mergeSimilar ? SignalVerdict.Malicious : SignalVerdict.Likely_malicious
  } else {
    return SignalVerdict.Malicious
  }
}

/**
 * Cast string verdict description to numeric signal strength value
 */
export function castVerdictToStrength(verdict: SignalVerdict | string): SignalStrength {
  switch(verdict) {
    case SignalVerdict.Benign:
      return SignalStrength.Whitelisted
    case SignalVerdict.Informational: // BC
    case SignalVerdict.No_threat:
      return SignalStrength.LowImpact
    case SignalVerdict.Suspicious:
      return SignalStrength.ConsiderableImpact
    case SignalVerdict.Likely_malicious:
    case 'likely malicious':
      return SignalStrength.SevereImpact
    case SignalVerdict.Malicious:
      return SignalStrength.CriticalImpact
    case SignalVerdict.Unknown:
    default:
      return SignalStrength.NoImpact
  }
}

/**
 * Detect signal type by it's strength
 *
 * @param {float} strength
 */
export function detectSignalType(strength: number | string): SignalType {
  return typeof strength === 'string' ?
    byStrengthCode(strength) :
    byStrengthValue(strength)
}

/**
 * Detect signal type, if strength is given by numeric value
 */
function byStrengthValue(strength: number): SignalType {
  if (strength < 0) {
    return SignalType.Benign
  }
  if (!strength) {
    return SignalType.Unknown
  }
  if (strength <= SignalStrength.LowImpact) {
    return SignalType.No_threat
  }
  if (strength <= SignalStrength.ConsiderableImpact) {
    return SignalType.Suspicious
  }

  return SignalType.Malicious
}

/**
 * Detect signal type, if strength is given by string verdict
 */
function byStrengthCode(strength: string): SignalType {
  const map: {[key: string]: SignalType} = {
    unknown: SignalType.No_threat,
    benign: SignalType.No_threat,
    informational: SignalType.No_threat,
    no_threat: SignalType.No_threat,
    suspicious: SignalType.Suspicious,
    likely_malicious: SignalType.Malicious,
    malicious: SignalType.Malicious,
  }

  strength = strength.toLowerCase()

  if (!has(map, strength)) {
    throw new Error(`Unknown strength code: ${strength}`)
  }

  return map[strength]
}

// Return all verdicts we use, except for those that are kept now only for BC
export function uniqueVerdicts() {
  const verdicts: {[key: string]: string} = {...SignalVerdict}
  delete verdicts.Informational

  return verdicts as unknown as UniqueSignalVerdict
}
