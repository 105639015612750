import { useLocation } from 'react-router-dom';
import { AdminFeature } from '../types';

export function useFeature(): AdminFeature | undefined {
  const location = useLocation()
  const match = location.pathname.match(/\/admin\/([0-9a-z_-]+)/)
  const feature = match ? match[1] as AdminFeature : undefined

  return feature
}
