import { HttpService } from './http.service'
import { apiRoutes } from '../routing/api-routes'

export const TermsService = {
  getPrivacyPolicy: (): Promise<string> => {
    return HttpService.get(apiRoutes.privacyPolicy)
  },
  getTermsCondition: (): Promise<string> => {
    return HttpService.get(apiRoutes.termsCondition)
  },
  getCookiePolicy: (): Promise<string> => {
    return HttpService.get(apiRoutes.cookiePolicy)
  }
}
