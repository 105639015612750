import React, { useContext } from 'react'
import { Modal, ModalProps as BSModalProps } from 'react-bootstrap'
import { modalContext } from '../modules/shared'
import { ModalStateData, ModalWrapperType } from '../modules/shared/types'

export type ModalProps = {
  body: JSX.Element,
  title?: JSX.Element | string,
  show?: boolean, // `show` and `hide` moved here for more comfort in use, as we use them always, but `props` - not always
  hide?: boolean,
  props?: Omit<BSModalProps, 'show' | 'hide'>
}

/**
 * Get callback to be used to show modal and update it's contents
 */
export function useModal(): [(options: ModalProps) => void, () => void] {
  const modalWrapper = useContext(modalContext)
  const state: ModalStateData = modalWrapper.state

  const open = (options: ModalProps) => {
    options.show = true

    const newState = cloneState(options)
    modalWrapper.setState(newState)
  }

  const hide = () => {
    const newState = {...cloneState(state), show: false}
    modalWrapper.setState(newState)
  }

  return [open, hide]
}

/**
 * Set new values for ModalState instance
 * @param modalState
 * @param values
 */
export function updateModalState(modalState: ModalStateData, values: { [key: string]: any }): ModalStateData {
  values = cloneState(values)
  const newModal: ModalStateData = cloneState(modalState)

  if (typeof values.footer === 'undefined') {
    values.footer = null
  }
  if (typeof values.props === 'undefined') {
    values.props = {}
  }
  if (typeof values.props.dialogClassName === 'undefined') {
    values.props.dialogClassName = undefined
  }

  Object.keys(values).forEach(key => {
    newModal[key] = values[key]
  })

  return newModal
}

/**
 * Get callback to be used to show modal and update it's contents
 * @DEPRECATED. `useModal` hook should be used instead
 *
 * @param modalWrapper
 * @param values
 */
export function getUpdateModalCallback(modalWrapper: ModalWrapperType, values: { [key: string]: any }): () => void {
  return () => {
    const newModalState: ModalStateData = updateModalState(modalWrapper.state, values)

    modalWrapper.setState(newModalState)
  }
}

/**
 * Generic modal, customizable with props
 */
export function GenericModal(): JSX.Element {
  const modalWrapper = useContext(modalContext)
  const state: ModalStateData = modalWrapper.state
  const [openModal, hideModal] = useModal()

  const footerComponent = state.footer ? (
    <Modal.Footer>{state.footer}</Modal.Footer>
  ) : null

  const mergedProps = mergeDefaultProps(state.props)
  const titleClass = state.titleClassName ?? undefined
  const header = state.title ? (
    <Modal.Header closeButton={false}>
      <Modal.Title className={titleClass}>
        {state.title}
      </Modal.Title>
      <span className='ds3-icon ds3-close' onClick={() => hideModal()}></span>
    </Modal.Header>
  ) : null

  return (
    <Modal show={state.show} animation={false} {...mergedProps}>
      {header}

      <Modal.Body>
        {state.body}
      </Modal.Body>

      {footerComponent}
    </Modal>
  )
}

// Footer component to be placed directly inside modal body component
export function ModalFooterInBody({children, contentClass}: {children: any, contentClass?: string}) {
  return (
    <div className="modal-footer row g-0">
      <div className={'modal-footer-content d-flex flex-row align-items-center ' + (contentClass || 'justify-content-end')}>
        {children}
      </div>
    </div>
  )
}

function mergeDefaultProps(props: { [key: string]: any }) {
  const merged = { ...props }

  if (typeof merged.size === 'undefined') {
    merged.size = 'lg'
  }
  if (typeof merged.centered === 'undefined') {
    merged.centered = true
  }

  return merged
}

function cloneState(values: {[key: string]: any}) {
  return {...values, props: {...(values.props || {})}}
}
