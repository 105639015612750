import { ReportWrapperConstructor } from './report-wrapper-base';

export function OverviewMitreMixin<TBase extends ReportWrapperConstructor>(Base: TBase): any {
  return class ReportWithOverviewMitre extends Base {
    /**
     * Get all MITRE tactics and techniques
     */
    getGeneralMitreData() {
      const data = this.getAllReportsData();
      return data?.mitre;
    }

    /**
     * Get signals groups count for given MITRE technique id for given report
     */
    getMitreTechniqueGroupsCount(reportId: string, techniqueId: string) {
      const report = this.getReport(reportId);
      const map = report?.overview.techniques_groups_map;
      return map && map[techniqueId]?.count;
    }

    /**
     * Get ids of signal groups for given technique id
     */
    getGroupsIdsForTechnique(reportId: string, techniqueId: string): { [group: string]: string | undefined } | undefined {
      const report = this.getReport(reportId);
      const map = report?.overview.techniques_groups_map;
      const groups = map && map[techniqueId]?.groups;

      if (!groups) {
        return;
      }

      // Convert array to object with groups ids as keys
      // to speed up search for arbitrary group among them
      return groups.reduce((result, group) => {
        return {
          ...result,
          [group]: '',
        };
      }, {});
    }

    /**
     * Group MITRE techniques by tactics they hold.
     * Techniques/tactics format here is pretty simple, just the names.
     *
     * TODO: Add typings to OverviewMitreMixin.groupTechniquesByTactics'
     */
    groupTechniquesByTactics(techniques: any) {
      const result: any = {};

      for (const id in techniques) {
        const technique = techniques[id];
        const tacticName: string = technique.tactic;

        if (typeof result[tacticName] === 'undefined') {
          result[tacticName] = [];
        }

        result[tacticName].push(technique.name);
      }

      return result;
    }
  };
}
