import BaseFormatter from './base'
import { has, hasFields, isObject } from '../../helpers'

/**
 * Format report response data for PDF file details page
 */
export default class PdfDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: {[key: string]: any}, report: {[key: string]: any}): void {
    const result: {[key: string]: any} = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = (!data?.file?.type || data.file.type === 'other') ? 
      this.getFileType(resource) : data.file.type
    if (fileType !== 'pdf') {
      return
    }

    const pdfData = resource.extendedData
    this.groupOverview(resource, result)
    this.groupStat(pdfData, result)
    this.formatXmp(pdfData, result)
    this.copyGrouped(pdfData, result)

    if (has(resource, 'metaData')) {
      result.meta = resource.metaData
    }
    if (has(resource, 'certInfos')) {
      result.certificates = resource.certInfos
    }

    if (hasFields(result)) {
      report.pdf_details = result
      report.pdf_details.overview = { ...report.details.overview, ...report.pdf_details.overview }
      delete report.details
    }
  }

  /**
   * Gather data to display in overview block
   *
   * @param data
   * @param result
   */
  groupOverview(resource: {[key: string]: any }, result: {[key: string]: any }): void {
    const overview: {[key: string]: any } = {}
    const data = resource.extendedData
    const names = ['author', 'creator', 'producer', 'isEncrypted']
    names.forEach(name => {
      if (has(data, name)) {
        overview[name] = data[name]
      }
    })

    if (hasFields(overview)) {
      result.overview = overview
    }
  }

  /**
   * Gather data to display in overview block under statistic header
   *
   * @param data
   * @param result
   */
  groupStat(data: {[key: string]: any }, result: {[key: string]: any }): void {
    const stat: {[key: string]: any } = {}
    const names = ['pages']

    names.forEach(name => {
      if (has(data, name)) {
        stat[name] = data[name]
      }
    })

    if (hasFields(stat)) {
      result.stat = stat
    }
  }

  formatXmp(data: {[key: string]: any }, result: {[key: string]: any }): void {
    if (!has(data, 'xmp')) {
      return
    }

    const xmp: {[key: string]: any} = {}
    Object.keys(data.xmp).forEach(key => {
      const item = data.xmp[key]
      const skip =
        (Array.isArray(item) && !item.length) ||
        (isObject(item) && !hasFields(item))

      if (skip) {
        return
      }

      if (Array.isArray(item) && item.length === 1) {
        xmp[key] = item[0]
      } else {
        xmp[key] = item
      }
    })

    if (hasFields(xmp)) {
      result.xmp = xmp
    }
  }

  /**
   * Just copy data that is pregrouped to result report
   *
   * @param data
   * @param result
   */
  copyGrouped(data: {[key: string]: any }, result: {[key: string]: any }): void {
    const names = ['pdfid']

    names.forEach(name => {
      if (has(data, name)) {
        result[name] = data[name]
      }
    })
  }
}
