
import { applicationConfiguration } from "app/modules/shared"
import React, { useContext } from "react"

export function ExtraButton() {
  const config = useContext(applicationConfiguration).extraButton
  const text = config?.text ?? ''
  const link = config?.link ?? ''

  if (!config?.active) {
    return null
  }
  if (!text || !link) {
    console.warn('Extra button is not configured correctly, both link and text should be set')
    return null
  }

  return (
    <div className='appbar-item-wrapper'>
      <a className='btn btn-primary me-3 extra-button text-decoration-none' href={link} target='_blank' rel='noreferrer'>
        {text}
      </a>
    </div>
  )
}
