import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation';
import { ExtendedUploadOptions } from './types';
import { HelpIcon, getAdvancedOptionsFields } from './helpers';
import { useContext } from 'react';
import { applicationConfiguration } from '../../../shared';
import OpswatMarketingAlert from '../opswat-marketing-alert';
import { TFunction } from 'i18next';
import ExtendedOptionsBody from './extended-options-body';
import { FormControl } from 'app/modules/shared/components/form/form-control';
import Checkbox from 'app/components/checkbox';

type ExtendedScanOptionsParams = {
  data: ExtendedUploadOptions,
  setData: CallableFunction,
  savePreset: boolean,
  setSavePreset: CallableFunction,
  disabled: boolean
}

export type ExtendedOption = {
  label: string,
  note: string,
  name: string
}

export function ExtendedScanOptions({ data, setData, savePreset, setSavePreset, disabled }: ExtendedScanOptionsParams) {
  const { _t } = useTypedTranslation()
  const config = useContext(applicationConfiguration)
  const [allChecked, setAllChecked] = useState(true)

  const fields: ExtendedOption[][] = getAdvancedOptionsFields(_t as TFunction)

  function setValue(name: string, value: any) {
    const values: { [key: string]: any } = { ...data }
    values[name] = value

    setData(values as ExtendedUploadOptions)
  }

  function onOptionChange(name: string) {
    return (value: boolean) => {
      setValue(name, value)

      if (!value) {
        setAllChecked(false)
        return
      }

      // Change state of `all checked` option
      let allFieldsChecked = true
      fields.forEach(group => {
        group.forEach(field => {
          if (field.name !== name && !data[field.name as keyof ExtendedUploadOptions]) {
            allFieldsChecked = false
          }
        })
      })

      allFieldsChecked && setAllChecked(true)
    }
  }

  function onAllChange() {
    const value = !allChecked
    setAllChecked(value)
    setAllValues(value)
  }

  function setAllValues(value: boolean, others: { [key: string]: boolean } = {}) {
    const values: { [key: string]: any } = { ...data }

    fields.forEach(group => {
      group.forEach(field => {
        values[field.name] = value
      })
    })
    Object.keys(others).forEach(key => {
      values[key] = others[key]
    })

    setData(values)
  }

  function setRapidMode(value: boolean) {
    setAllValues(!value, { rapidMode: value })
  }

  return (
    <>
      {
        disabled && config.buildType === 'community' ?
        <OpswatMarketingAlert />
        : null
      }

      <ExtendedOptionsBody
        data={data}
        disableFields={disabled}
        fields={fields}
        onOptionChange={onOptionChange}
        setRapidMode={setRapidMode}
        _t={_t as TFunction}
      />

      <div>
        <Button className="btn btn-text btn-sm" onClick={onAllChange} disabled={data.rapidMode || disabled}>{_t('all-active')}</Button>
      </div>

      <p className="text-muted mb-3 mt-6">
        <i>{_t('personalization')}</i>
      </p>

      <FormControl
        parentClass='d-flex mb-0 align-items-start'
        control={<>
          <Checkbox
            id="save-options"
            label={_t('save-preset')}
            disabled={disabled}
            className="d-inline-flex align-items-center"
            checked={savePreset}
            onChange={(checked: boolean) => setSavePreset(checked)}
          />
          <HelpIcon tooltipId={'savePresetTooltip'} note={_t('save-preset-note')} />
        </>}
      />
    </>
  )
}
