import React, { useContext, Suspense, useEffect } from "react";
import { PageData } from './types'
import pageResolver from "./resolve-page";
import Title from '../components/title'
import { UsersLayout, AdminsLayout, Layout } from '../../../components/layouts'
import { applicationConfiguration } from '../context/configuration-context';
import PageLoader from "../components/loader";
import { logger } from "../../../components";
import { routeState } from "..";
import { GoToLocaionHash } from "app/components/hash-link";
import { GetBackAfterSignin } from "./back-after-signin";
import { eventsHandler } from "../events/queue";
import { Event } from "../events/events";

export function RenderedRouteWrapper(
  {routeProps, page}: {routeProps: {[key: string]: any}, page: PageData}
): JSX.Element {
  const config = useContext(applicationConfiguration)
  eventsHandler.trigger(Event.PageLoad, page.key)

  if (config.firstRun && ['sign-up', 'first-signup-redirect'].indexOf(page.key as string) === -1) {
    return pageResolver.redirectTo('sign-up')
  }

  const resolveResult = pageResolver.resolve(page.key as string)
  logger.debug('Page resolved to ', resolveResult)

  const PageComponent = preparePageComponent(resolveResult as CallableFunction, routeProps, page)
  const Wrapper: CallableFunction | undefined = (
    resolveResult === GetBackAfterSignin ? undefined : page.wrapper
  )

  const content = Wrapper ? (
    //@ts-expect-error CallableFunction as JSX component
    <Wrapper {...routeProps}>
      <PageComponent />
    </Wrapper>
  ) :  (
    <PageComponent />
  )

  const LayoutComponent = page.layout === Layout.Admins ? AdminsLayout : UsersLayout
  return (
    <LayoutComponent>
      <Suspense fallback={<PageLoader/>}>
        {content}
      </Suspense>
    </LayoutComponent>
  )
}

function preparePageComponent(resolveResult: CallableFunction, routeProps: {[key: string]: any}, page: PageData) {
  const PageComponent = resolveResult

  function FullPageComponent() {
    const pageState = useContext(routeState)

    useEffect(() => {
      if (pageState.data.page !== page) {
        pageState.setValues({page: page})
      }
    }, [])

    return (
      <>
        <GoToLocaionHash />
        <Title title={page.title} />
        {/*@ts-expect-error CallableFunction as JSX component*/}
        <PageComponent {...routeProps} page={page}/>
      </>
    )
  }

  return FullPageComponent
}
