import React from "react"

export function CardHeaderNameValueCol(
  {name, value, className, valueClass, labelClass}:
  {name: string, value: string | JSX.Element, className?: string, valueClass?: string, labelClass?: string}
) {
  return (
    <div className={"flex-column justify-content-center align-items-start " + (className || '')}>
      <div className={"text-label-upper " + (labelClass || '')}>{name}</div>
      <div className={valueClass || ''}>{value}</div>
    </div>
  )
}
