export enum NodeType {
  process = 'process',
  file = 'file',
  network = 'network',
  script = 'script',
  execute = 'execute',
  other = 'other'
}

export type TreeNode = {
  id: string;
  parent: string;
  type: NodeType;
  action: string;
  interesting: boolean;
  source: string;
  extra: { [key: string]: string };
  original: { [key: string]: any };
  childs: TreeNode[];
}

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
}
