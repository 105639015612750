import { useContext, useEffect } from 'react'
import { applicationConfiguration } from '../modules/shared'
import { environment } from '../../environments/environment'
import { initializeGoogleAnalytics, initializeMatomoAnalytics } from 'app/modules/shared/helpers'
import { getCookieConsent } from 'app/modules/auth/helper/get-cookie'


/**
 * Base app init
 */
export function BaseInit(): null {
  const config = useContext(applicationConfiguration)

  // Init actions that depend on fetched config
  useEffect(() => {
    if (!config) return;

    const initAnalytics = () => {
      if (environment.production && getCookieConsent('cookieyes-consent', 'analytics')) {
        initializeGoogleAnalytics(config)
        initializeMatomoAnalytics(config)
      }
    }

    // Add cookie consent event listeners for CookieYes tool
    const handleCookieconsentUpdate = (eventData: Event) => {
      const data = (eventData as CustomEvent)?.detail
      
      if (data?.accepted?.includes("analytics")) {
        initAnalytics()
      }

      document.removeEventListener("cookieyes_consent_update", handleCookieconsentUpdate)
    }

    document.addEventListener("cookieyes_consent_update", handleCookieconsentUpdate)

    initAnalytics()

    return () => document.removeEventListener("cookieyes_consent_update", handleCookieconsentUpdate);
  }, [config])

  return null
}
