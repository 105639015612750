import { generatePath, useParams } from 'react-router-dom';
import { useExport } from '../contexts/export-context';
import { useRouteMatch } from 'app/modules/shared/hooks';

export interface ReportParams {
  [key: string]: string | undefined;
  flowId?: string;
  reportId?: string;
  hash?: string;
  subpage?: string;
}
export function useReport(): ReportParams {
  const params = useParams<ReportParams>()
  const exportParams = useExport()

  if (Object.keys(params).length) {
    return params
  } else {
    return {
      flowId: exportParams.flowId,
      reportId: exportParams.reportId.toString(),
      subpage: exportParams.subpage
    }
  }
}

export function useReportLink(id: string, page: string): string {
  const { flowId, hash } = useReport()
  const pathTmpl = useRouteMatch()

  return generatePath(pathTmpl, {flowId, reportId: id, hash, subpage: page})
}
