import React, { createContext, useState } from 'react'

export type NotificationMessage = string | string[] | JSX.Element | JSX.Element[]
export type NotificationData = {
  message: NotificationMessage,
  messageType: string,
  timeout: number,
  setMessage: CallableFunction,
  setMessageType: CallableFunction,
  setMessageData: (message: NotificationMessage, messageType: string, timeout?: number) => void
}

export const mainNotificationContext = createContext({message: '', messageType: '', timeout: 5000} as NotificationData)
export const defaultTimeout = 5000

/**
 * Context for main notification
 */
export function WithMainNotificationContext({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const [message, setMessage] = useState<NotificationMessage>('')
  const [messageType, setMessageType] = useState('')
  const [timeout, setTimeout] = useState(defaultTimeout)

  function setMessageData(message: NotificationMessage, messageType: string, timeout = defaultTimeout): void {
    setMessage(message)
    setMessageType(messageType)
    setTimeout(timeout)
  }

  if (message && !messageType) {
    setMessageType('danger')
  }

  const provided: NotificationData = {message, messageType, timeout, setMessage, setMessageType, setMessageData}

  return (
    <mainNotificationContext.Provider value={provided}>
      {children}
    </mainNotificationContext.Provider>
  )
}
