import { ReportWrapperBase } from './report-wrapper-base';
import { JobErrorMixin } from './job-error-mixin';
import { OverviewMitreMixin } from './overview-mitre-mixin';
import { SubpagesMixin } from './subpages-mixin';
import { ReportStateMixin } from './report-state-mixin';
import { ReportMixin } from './report-mixin';
import { ReportsListMixin } from './reports-list-mixin';

const withError = JobErrorMixin(ReportWrapperBase);
const withSubpages = SubpagesMixin(withError);
const withOverviewMitre = OverviewMitreMixin(withSubpages);
const withReportState = ReportStateMixin(withOverviewMitre);
const withReport = ReportMixin(withReportState);
const withReportsList = ReportsListMixin(withReport);

const ReportsWrapper = withReportsList;

// This is needed to correctly obtain wrapper type,
// as we build this class dynamically from mixins.
const _temp = new ReportsWrapper(undefined);
type ReportsWrapperType = typeof _temp;

export { ReportsWrapper, ReportsWrapperType };
