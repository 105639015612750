import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { applicationConfiguration } from '../context/configuration-context'
import { reportContext } from '../../reports/contexts'
import { capitalize } from '../../reports/helpers/functions'
import { useTypedTranslation } from '../hooks/use-translation';

/**
 * Set title for any page
 */
export default function Title({title}: {title?: string}): JSX.Element {
  const { _t } = useTypedTranslation()
  const config = useContext(applicationConfiguration)
  const reportsWrapper = useContext(reportContext)
  const { subpage, reportId } = useParams<any>()

  // Handle some special cases
  let _title = ''
  if (title) {
    _title = _t(title)

    // reportsWrapper may be empty in case access to page was forbidden
    if (reportId && reportsWrapper) {
      _title = _t(title, { hash: reportsWrapper.getFileHash(reportId) })
    }

    if (subpage) {
      _title += ' - ' + capitalize(subpage)
    }
  }

  const fullTitle = _title ? `${config?.appTitle} - ${_title}` : `${config?.appTitle} - ${config?.appDescription}`
  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  )
}
