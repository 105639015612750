import { environment } from '../../../environments/environment';

export const logger = {
    info(...args : any[]): void {
        console.info(...args);
    },
    debug(...args : any[]): void {
      if(!environment.production)
        console.info(...args);
    },
    warn(...args : any[]): void {
      if(!environment.production)
        console.warn(...args);
    },
    error(...args : any[]): void {
      if(!environment.production)
        console.error(...args);
    }
};
