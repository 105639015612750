import { IOCDataTypes, IOCGroupedByDataType } from "../types"

export const queryExceptionIOCs = [
  IOCDataTypes.UUIDs,
  IOCDataTypes.RegistryPathways,
  IOCDataTypes.RevisionSaveIDs
];

export function hasInterestingIOCs(data: IOCGroupedByDataType): boolean {
  for (const iocType in data) {
    if (queryExceptionIOCs.includes(iocType as IOCDataTypes)) continue

    const iocData = data[iocType as IOCDataTypes]
    if (!iocData || !iocData.length) continue

    for (let i = 0; i < iocData.length; i++) {
      const refs = iocData[i].references
      for (let j = 0; j < refs.length; j++) {
        if (refs[j].interesting) return true;
      }
    }
  }

  return false;
}
