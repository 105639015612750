import React, { Suspense } from 'react'
import {
  WithConfigurationContext,
  WithModalContext,
  WithMainNotificationContext,
  WithRouteStateContext,
  WithAssetsContext
} from '../modules/shared'
import { GenericModal } from '../components'
import { WithAuthContext } from '../modules/auth'
import { WithCache } from '../modules/shared/cache'
import { BaseInit } from './base-init'
import { ThemeProvider } from 'app/modules/shared/context/theme-context'

/**
 * All application wrapper components
 */
export function AppWrappers({ children }: { children?: React.JSX.Element }): React.JSX.Element {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WithMainNotificationContext>
        <WithConfigurationContext>
          <WithAssetsContext>
            <WithCache>
              <WithRouteStateContext>
                <WithAuthContext>
                  <ThemeProvider>
                    <WithModalContext>
                      <BaseInit />
                      {children!}
                      <GenericModal />
                    </WithModalContext>
                  </ThemeProvider>
                </WithAuthContext>
              </WithRouteStateContext>
            </WithCache>
          </WithAssetsContext>
        </WithConfigurationContext>
      </WithMainNotificationContext>
    </Suspense>
  )
}
