import React, { Children } from 'react';
import { Container, Row, Card } from 'react-bootstrap';

interface CardLayoutProps {
  title?: string;
  header?: JSX.Element | null;
  children: JSX.Element | null | (JSX.Element | null)[];
  className?: string,
}

/**
 * Simple layout with one card in the middle.
 * `children` are rendered into this card body
 */
export const CardLayout = ({ children, header, title, className }: CardLayoutProps): JSX.Element => {
  const titleComponent = title ? <div className="mb-4 h4 text-capital">{title}</div> : null
  const headerComponent = header ? <Card.Header bsPrefix='card-header card-header-transparent'>{header}</Card.Header > : null

  return (
    <Container className="my-4">
      <Card style={{ maxWidth: 480, margin: 'auto', width: '100%' }} className={'single-card ' + className}>
        {headerComponent}
        <Card.Body>
          <div>
            {titleComponent}
            {children}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export const SingleCardFooter = ({children, className}: {children: any, className?: string}) => {
  if (!className) {
    className = 'justify-content-end'
  }

  return (
    <div className={"d-flex mt-10 align-items-center " + (className || '')}>
      {children}
    </div>
  )
}
