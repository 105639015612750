import { HttpService } from './http.service'
import { ApplicationConfiguration, ReputationCheckConfigResponse } from '../types'
import { apiRoutes } from '../routing/api-routes'

export const ConfigurationService = {
  getConfiguration: (): Promise<ApplicationConfiguration> => {
    return HttpService.get(apiRoutes.config)
  },
  getReputationCheckConfig: (): Promise<ReputationCheckConfigResponse> => {
    return HttpService.get(apiRoutes.reputationCheckConfig)
  }
}
