import { buildReportLink } from '../helpers';
import { VerdictData } from '../types';
import { ReportWrapperConstructor } from './report-wrapper-base';

export function ReportMixin<TBase extends ReportWrapperConstructor>(Base: TBase): any {
  return class ReportWithReport extends Base {
    refreshReportCallback: (() => void) | undefined;

    /**
     * Get file name by report id
     */
    getFileName(reportId: string) {
      const report = this.getReport(reportId);
      return report?.file?.name ?? ''
    }

    /**
     * Sets a callback which can be called if refreshing the report is needed
     */
    setRefreshReportCallback(callback: () => void) {
      this.refreshReportCallback = callback
    }

    /**
     * Refreshes the report via the callback
     */
    refreshReport() {
      this.refreshReportCallback ? this.refreshReportCallback() : null;
    }

    /**
     * Get file hash by report id
     */
    getFileHash(reportId: string) {
      const report = this.getReport(reportId);
      return report?.file.hash;
    }

    /**
     * Get file category by report id
     */
    getFileCategory(reportId: string) {
      const report = this.getReport(reportId);
      return report?.file.type;
    }

    /**
     * Get current file report id
     */
    getCurrentReportId() {
      const pathname = window.location.pathname;
      const match = pathname.match(/\/reports\/([^/]+)\/[^/]+/);

      return match ? match[1] : null;
    }

    /**
     * Construct link to go to specific report overview page
     *
     * @param {string} reportId
     * @param {string} subpage
     */
    getReportLink (reportId: string, subpage: string) {
      const flowId = this.getFlowId() as string

      return buildReportLink(flowId, reportId, subpage)
    }

    /**
     * Get final report verdict
     *
     * @param {string} reportId
     */
    getVerdict(reportId: string): VerdictData | undefined {
      const report = this.getReport(reportId);
      return report?.verdict;
    }

    /**
     * Get time estimate for given report
     */
    getTimeEstimate(reportId: string): number | undefined {
      const report = this.getReport(reportId)
      return report?.estimatedTime
    }

    /**
     * Get estimated progress for given report
     */
    getEstimatedProgress(reportId: string): number | undefined {
      const report = this.getReport(reportId)
      return report?.overview.estimatedProgress
    }

    getGroupVerdicts(reportId: string): any {
      const report = this.getReport(reportId);
      return report?.groupVerdicts;
    }

    /**
     * Get flag indicating that all attempts to load derived files are finished
     */
    getReportFilesLoadingState(reportId: string): boolean | undefined {
      const report = this.getReport(reportId);
      return report?.filesDownloadFinished;
    }

    /**
     * Get ChatGPT summary on report, if it already exists in report model
     */
    getChatGptSummary(reportId: string) {
      const report = this.getReport(reportId);
      return report?.chatGptSummary;
    }
  }
}
