import BaseFormatter from './base'
import { has, hasFields } from '../../helpers'

/**
 * Format report response data for MS Office file details page
 */
export default class MsOfficeDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = (!data?.file?.type || data.file.type === 'other') ?
      this.getFileType(resource) : data.file.type
    if (fileType !== 'ms-office') {
      return
    }

    const msData = resource.extendedData
    this.groupOverview(resource, result)
    this.copyGrouped(msData, result)
    this.parseSignature(resource, result)

    if (has(resource, 'metaData')) {
      result.meta = resource.metaData
    }

    if (hasFields(result)) {
      report.ms_office_details = result
      report.ms_office_details.overview = { ...report.details.overview, ...report.ms_office_details.overview }
      delete report.details

      const otherFields = ['onenoteArtifacts', 'onenoteEmbedded', 'onenoteHeaders']
      this.copyFields(otherFields, msData, report.ms_office_details)
    }

  }

  /**
   * Gather data to display in overview block
   *
   * @param data
   * @param result
   */
  groupOverview(resource: { [key: string]: any }, result: { [key: string]: any }): void {
    const overview: { [key: string]: any } = {}

    const data = resource.extendedData
    const names = ['vbaStomping']
    names.forEach(name => {
      if (has(data, name)) {
        overview[name] = data[name]
      }
    })

    if (hasFields(overview)) {
      result.overview = overview
    }
  }

  /**
   * Just copy data that is pregrouped to result report
   *
   * @param data
   * @param result
   */
  copyGrouped(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const names: { [key: string]: any } = {
      'olevbaStrings': 'olevbaStrings',
      'streams': 'oleStreams'
    }

    Object.keys(names).forEach(name => {
      if (has(data, name)) {
        const useName = names[name]
        result[useName] = data[name]
      }
    })
  }

  /**
   * Extract signature data from emulation data
   */
  parseSignature(data: { [key: string]: any }, result: { [key: string]: any }) {
    if (!has(data, 'emulationData') || !Array.isArray(data.emulationData)) {
      return;
    }

    let signature: any = null;
    for (const item of data.emulationData) {
      if (item.action !== 'OfficeFileInformation') continue;
      if (!item.additionalInformation || !item.additionalInformation.Signature) continue;

      signature = item.additionalInformation.Signature;
      break;
    }

    if (!signature) return;

    const certificates: any[] = [];
    let active: any = {};
    Object.keys(signature).forEach((key: string) => {
      if (key === 'Certificates' && Array.isArray(signature.Certificates)) {
        certificates.push(...signature.Certificates);
      } else if ('ActiveCertificate' in signature[key] && 'Verification' in signature[key].ActiveCertificate) {
        active = signature[key].ActiveCertificate;
        active.active = true;
      }
    });

    if (active) {
      for (let i = 0; i < certificates.length; i++) {
        if (certificates[i].SubjectName === active.SubjectName) {
          certificates.splice(i, 1);
          certificates.unshift(active);
          break;
        }
      }
    } else {
      certificates.unshift(active);
    }

    result.certificates = certificates;
  }
}
