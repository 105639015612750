import { AuthContextData } from 'app/modules/auth/context';
import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { ExtendedUploadOptions } from './types';
import { TFunction } from 'i18next';
import { Placement } from 'react-bootstrap/esm/types';

export function HelpIcon({tooltipId, note, placement}: {tooltipId: string, note: string, placement?: Placement}) {
  return (
    <OverlayTrigger
      placement={placement || "right"}
      overlay={<Tooltip id={tooltipId}>{note}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <i
          {...triggerHandler}
          ref={ref}
          className="ds3-icon ds3-information icon-button text-muted-light fa-2 ms-1 font-size-15"
        ></i>
      )}
    </OverlayTrigger>
  )
}

export function getDefaultModalState(auth: AuthContextData, extendedUploadOptionsDefaults: ExtendedUploadOptions) {
  const saved = auth.user?.scanTransformOptions || {}
  return {...extendedUploadOptionsDefaults, ...saved}
}

export function getAdvancedOptionsFields(_t: TFunction) {
  return [
    [
      { label: _t('input-file-yara'), note: _t('input-file-yara-note'), name: 'inputFileYara' },
      { label: _t('extracted-files-yara'), note: _t('extracted-files-yara-note'), name: 'extractedFilesYara' },
      { label: _t('file-visualization'), note: _t('file-visualization-note'), name: 'visualization' },
      { label: _t('images-ocr'), note: _t('images-ocr-note'), name: 'imagesOcr' },
      { label: _t('files-download'), note: _t('files-download-note'), name: 'filesDownload' }
    ],
    [
      { label: _t('osint-lookups'), note: _t('osint-lookups-note'), name: 'osint' },
      { label: _t('extended-osint-lookups'), note: _t('extended-osint-lookups-note'), name: 'extendedOsint' },
      { label: _t('extracted-files-osint-lookups'), note: _t('extracted-files-osint-lookups-note'), name: 'extractedFilesOsint' },
      { label: _t('resolve-domains'), note: _t('resolve-domains-note'), name: 'resolveDomains' },
      { label: _t('whois'), note: _t('whois-note'), name: 'whois' },
      { label: _t('ips-meta'), note: _t('ips-meta-note'), name: 'ipsMeta' },
    ]
  ]
}
