import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { HelpIcon } from './helpers'
import { ExtendedUploadOptions } from './types'
import { ExtendedOption } from './extended-options'
import { TFunction } from 'i18next'
import { ScanOptions } from '../../types'
import snakeCase from 'lodash/snakeCase'
import { FormControl } from 'app/modules/shared/components/form/form-control'
import Checkbox from 'app/components/checkbox'

const ExtendedOptionsBody = ({
  disableFields,
  _t,
  fields,
  data,
  setRapidMode,
  onOptionChange,
  scanOptions
}: {
  disableFields: boolean,
  _t: TFunction,
  fields: ExtendedOption[][],
  data?: ExtendedUploadOptions,
  setRapidMode?: CallableFunction,
  onOptionChange?: CallableFunction,
  scanOptions?: ScanOptions
}) => (
  <>
    <p className="text-muted mb-3">
      <i>{_t('generic-options-note')}</i>
    </p>

    <FormControl
      parentClass='d-flex mb-4 align-items-start'
      control={<>
        <Checkbox
          id="rapidMode"
          label={_t('rapid-mode').toString()}
          className="d-inline-flex align-items-center"
          checked={data?.rapidMode || scanOptions?.[snakeCase('rapid_mode') as keyof ScanOptions] as boolean || false}
          onChange={(checked) => setRapidMode && setRapidMode(checked)}
          disabled={disableFields}
        />
        <HelpIcon tooltipId='rapid-mode-tooltip' note={_t('rapid-mode-tooltip')} />
      </>}
    />

    <p className="text-muted mb-3">
      <i>{_t('extended-scan-options-note')}</i>
    </p>

    <div className="custom-popover-body row">
      {fields.map((group, idx) => (
        <div className='col' key={idx}>
          {group.map(field => (
            <FormControl
              key={field.name}
              parentClass='d-flex mb-4 align-items-start'
              control={<>
                <Checkbox
                  id={field.name}
                  label={field.label}
                  className="d-inline-flex align-items-center"
                  checked={data?.[field.name as keyof ExtendedUploadOptions] as boolean || scanOptions?.[snakeCase(field.name) as keyof ScanOptions] as boolean || false}
                  onChange={onOptionChange && onOptionChange(field.name)}
                  disabled={data?.rapidMode || disableFields}
                />
                <HelpIcon tooltipId={field.name + 'Tooltip'} note={field.note} />
              </>}
            />
          ))}
        </div>
      ))}
    </div>
  </>
)

export default ExtendedOptionsBody
