import { ExtendedUploadOptions } from 'app/modules/reports/components/file-upload-modal/types'
import { Feature } from './feature'

export enum UserNotification {
  DarkTheme = 'dark_theme'
}

export type User = {
  accountId: string
  username: string
  email: string
  isAdmin?: boolean
  isAuditor?: boolean
  isPasswordEmpty?: boolean
  avatarLastModified: string,
  allowedFeatures?: Feature[],
  apiKey?: string,
  theme?: string;
  timezone?: string
  locale?: string
  scanTransformOptions?: ExtendedUploadOptions
  externalAuth?: {
    service: string
    userId: string
  }
  notifications?: UserNotification[]
}
