import React from "react";
import { Tooltip as BSTooltip, OverlayTrigger } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

export function Tooltip(
  {id, children, content, placement = 'bottom'}:
  {id?: string, children: any, content: string | JSX.Element, placement?: Placement}
) {
  return (
    <OverlayTrigger
      overlay={<BSTooltip id={id}>{content}</BSTooltip>}
      placement={placement}
      delay={200}
    >
      {children}
    </OverlayTrigger>
  )
}
