export enum Feature {
  Signup = 'signup',
  Signup_invite = 'signup_invite',
  Scan = 'scan',
  Simple_search = 'simple_search',
  Advanced_search = 'advanced_search',
  Search_no_date_limit = 'search_no_date_limit',
  File_download = 'file_download',
  User_management = 'user_management',
  Site_management = 'site_management',
  Feed_access = 'feed_access',
  Daily_feed = 'daily_feed',
  Threat_intel = 'threat_intelligence',
  Threat_intel_similarities = 'threat_intelligence_similarities',
  Advanced_intelligence = 'advanced_intelligence',
  Banner = 'banner',
  Healthcheck = 'healthcheck',
  Advanced_scanning = 'advanced_scanning',
  All_reports = 'all_reports',
  User_ecosystem = 'user_ecosystem',
  Report_moderation = 'report_moderation',
  Chat_gpt = 'chat_gpt',
  Restricted_statistics = 'restricted_statistics',
  Jobs_info = 'jobs_info',
  Private_report = 'private_report'
}
