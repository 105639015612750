import React, { useContext } from 'react'
import Appbar from '../appbar/appbar'
import { Footer } from '../footer'
import { FeatureNote } from '../notes'
import { MainNotification } from '../main-notification'
import { applicationConfiguration } from 'app/modules/shared'

/**
 * Layout for functionality to be used by daily users
 */
export function UsersLayout({children}: {children: JSX.Element | JSX.Element[]}): JSX.Element {
  const config = useContext(applicationConfiguration)

  return (
    <>
      <Appbar />
      <MainNotification />

      <div className="container-fluid main-container">
        {children}
      </div>

      {/* <FeatureNote />  Dark theme disabled with new ui*/}
      {config.showFooter ? <Footer /> : null}
    </>
  )
}
