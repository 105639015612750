import React, { ReactElement } from "react"
import { Navigate } from "react-router-dom"
import { getPageData, getPageUrl } from "./pages"
import { PageData, PageRedirectObject } from "./types"
import { UserRole } from "../../auth/types"
import { userStorage } from "../../auth/helper/storage"
import { checkAuth, checkFeature } from "./access-control"
import { GetBackAfterSignin } from "./back-after-signin"
import { logger } from "../../../components/logger"

/**
 * Functionality here is intended to provide redirect funtionality
 * on top of React Router redirect.
 * This is needed for cases when we have multiple redirects following each other.
 * We try to calculate them and perform a single real redirect
 * instead of doing all of them sequentially.
 */

const DEFAULT_REDIRECT = 'home'
const pageResolver = {redirectTo, forceRedirectTo, resolve}
export default pageResolver


// Redirect to given page, taking in account that it also might redirect somewhere
function redirectTo(pageKey: string, urlParams?: {[key: string]: string}) {
  const pageData = getPageData(pageKey) as PageData
  const resolveResult = resolve(pageKey, urlParams, true)
  const Component = (
    typeof resolveResult === 'string' ? forceRedirectTo(resolveResult) : resolveResult
  ) as React.FunctionComponent

  // Wrap result to supress TS type checking for `page` parameter
  function ResolveResultWrapper(props: {[key: string]: any}) {
    return <Component {...props}/>
  }

  return <ResolveResultWrapper page={pageData}/>
}

// Simply redirect to given page by means of React Router
function forceRedirectTo(pageKey: string, urlParams?: {[key: string]: string}) {
  let url = getPageUrl(pageKey)
  if (urlParams) {
    for (const key in urlParams) {
      url = url?.replace(':' + key, urlParams[key])
    }
  }

  return getRedirectComponent(url as string)
}

// Walk the chain of possible redirects to obtain final page,
// that should be shown to user.
function resolve(pageKey: string, urlParams?: {[key: string]: string}, onlyAsRedirect = false): string | CallableFunction {
  const visited: {[key: string]: boolean} = {}
  const initialPageKey = pageKey

  logger.debug('Resolving routing for page ',  pageKey)

  while (true) {
    const resolvedVisited = handleVisited(visited, pageKey)
    if (resolvedVisited) {
      return resolvedVisited
    }
    const resolved = checkConfigAndResolve(pageKey, initialPageKey, onlyAsRedirect, urlParams)

    // In case of access allowed or final redirect to other page, we received react component.
    // In case we still need to check if redirect to other page is allowed,
    // we received page key, optionally with url params.
    const isComponent = (
      typeof resolved === 'function' ||
      (typeof resolved === 'object' && typeof resolved?.page === 'undefined')
    )

    if (isComponent) {
      // Component may be not of type function, but it's not really important here.
      // We supress this to ease type checking
      return resolved as unknown as CallableFunction
    }

    const data = resolved as (string | PageRedirectObject) // Helper for TS type checking
    pageKey = typeof data === 'string' ? data : data?.page as string
    urlParams = typeof data === 'string' ? undefined : data?.urlParams

    logger.debug('Forwarded to page ', pageKey)
  }
}

// In case we've already discovered that access to this page is forbidden,
// just throw to activate special forbidden page.
function handleVisited(visited: {[key: string]: boolean}, pageKey: string) {
  if (visited[pageKey]) {
    return resolvePageOnForbidden(pageKey)
  }

  visited[pageKey] = true
}

// Check access to page and prepare correct result
function checkConfigAndResolve(
  pageKey: string,
  initialPageKey: string,
  onlyAsRedirect: boolean,
  urlParams?: {[key: string]: string}
): string | PageRedirectObject | CallableFunction {
  const pageData = getPageData(pageKey) as PageData
  const user = userStorage.getUser()

  // In case page access is verified in page own controller, allow access to it
  if (pageData.customAuthValidation) {
    return buildAllowedResolveResponse(pageKey, initialPageKey, onlyAsRedirect, urlParams)
  }

  // Handle case when redirect is directly specified in page config
  if (pageData.redirect) {
    logger.debug('Seemless redirect to ', pageData.redirect)
    return pageData.redirect
  }

  // Check feature and authorization role access
  const featureResolved = checkConfig(
    pageKey, initialPageKey, 'feature',
    () => checkFeature(pageData), onlyAsRedirect, urlParams
  )
  const authResolved = checkConfig(
    pageKey, initialPageKey, 'auth',
    () => checkAuth(user, pageData.auth as UserRole), onlyAsRedirect, urlParams
  )

  if (typeof featureResolved === 'undefined' && typeof authResolved === 'undefined') {
    throw 'Either need a feature or auth specified for page ' + pageKey
  }

  return (
    featureResolved ? featureResolved : authResolved
  ) as unknown as (string | PageRedirectObject)
}

// Analyze given page config to determine if access to page or redirect is allowed
function checkConfig(
  pageKey: string,
  initialPageKey: string,
  configName: string,
  checkFunc: CallableFunction,
  onlyAsRedirect: boolean,
  urlParams: undefined | {[key: string] : string}
) {
  const pageData = getPageData(pageKey) as PageData
  if (!pageData[configName as keyof PageData]) {
    return
  }

  const isAccessAllowed = checkFunc()
  if (isAccessAllowed) {
    return buildAllowedResolveResponse(pageKey, initialPageKey, onlyAsRedirect, urlParams)
  }

  return resolvePageOnForbidden(pageKey)
}

// If access to page is allowed, we either return:
// - page component
// - or component-wrapper around React Router redirect element
function buildAllowedResolveResponse(
  pageKey: string,
  initialPageKey: string,
  onlyAsRedirect: boolean,
  urlParams: undefined | {[key: string] : string}
): CallableFunction {
  if (pageKey === initialPageKey && !onlyAsRedirect && !urlParams) {
    logger.debug("Allow page, return it's own component")
    const pageData = getPageData(pageKey)
    return pageData?.component as CallableFunction
  }

  logger.debug("Allow page, return redirect to ", pageKey)
  return forceRedirectTo(pageKey, urlParams)
}

// If access to page is forbidden, resolve to other configured page
function resolvePageOnForbidden(pageKey: string) {
  const pageData = getPageData(pageKey)
  const redirect = pageData?.onForbidden
  if (redirect) {
    logger.debug('Page is forbidden, return `onForbidden` prop')
    return redirect
  }

  const user = userStorage.getUser()
  if (user) {
    logger.debug('Forbidden, return default redirect')
    return DEFAULT_REDIRECT
  }

  logger.debug('Forbidden, return get back after signin')
  return GetBackAfterSignin
}

// Wrap redirect element into component to return from resolver
function getRedirectComponent(url: string) {
  function RedirectComponent() {
    return <Navigate to={url} replace />
  }

  return RedirectComponent
}
