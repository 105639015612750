import { RedirectParams } from 'app/modules/shared/events/handlers/signin-redirect'
import { User, Feature } from '../types'

const accessTokenName = 'accessToken'
const refreshTokenName = 'refreshToken'
const userName = 'user'
const featuresName = 'allowedFeatures'
const signinRedirectName = 'signinRedirect'
const showAdvertisementName = 'showAdvertisementReportPage'

/**
 * Incapsulate methods for tokens browser storage
 */
export const tokensStorage = {
  getAccessToken() {
    return window.localStorage.getItem(accessTokenName)
  },

  getRefreshToken() {
    return window.localStorage.getItem(refreshTokenName)
  },

  saveAccessToken(token: string) {
    window.localStorage.setItem(accessTokenName, token)
  },

  saveRefreshToken(token: string) {
    window.localStorage.setItem(refreshTokenName, token)
  },

  clearAccessToken() {
    window.localStorage.removeItem(accessTokenName)
  },

  clearRefreshToken() {
    window.localStorage.removeItem(refreshTokenName)
  }
}

/**
 * Methods to work with browser user storage
 */
export const userStorage = {
  getUser() : User | undefined {
    const user = window.localStorage.getItem(userName)
    return user ? JSON.parse(user) : undefined
  },

  saveUser(user: User) {
    window.localStorage.setItem(userName, JSON.stringify(user))
  },

  clearUser() {
    window.localStorage.removeItem(userName)
  }
}

/**
 * Methods to work with allowed features storage
 */
 export const allowedFeaturesStorage = {
  getFeatures() {
    const features =  window.localStorage.getItem(featuresName)
    return features ? JSON.parse(features) : undefined
  },

  setFeatures(features: Feature[]) {
    if (features) {
      window.localStorage.setItem(featuresName, JSON.stringify(features))
    }
  },

  clearFeatures() {
    window.localStorage.removeItem(featuresName)
  }
}

/**
 * Save information about redirect that should be performed after signin
 */
export const signinRedirectStorage = {
  getRedirect(): RedirectParams | undefined {
    const redirect =  window.localStorage.getItem(signinRedirectName)
    return redirect ? JSON.parse(redirect) : undefined
  },

  saveRedirect(redirect: RedirectParams) {
    window.localStorage.setItem(signinRedirectName, JSON.stringify(redirect))
  },

  clearRedirect() {
    window.localStorage.removeItem(signinRedirectName)
  }
}

/**
 * Advertisement related storage - keep the information if the advertisement will be shown to the user
 */
export const advertisementInfoStorage = {
  getShowAdvertisement():boolean | null {
    const show =  window.sessionStorage.getItem(showAdvertisementName)

    if (show === 'true') {
      return true
    }

    if (show === 'false') {
      return false
    }

    return null
  },

  saveShowAdvertisement(show: boolean) {
    window.sessionStorage.setItem(showAdvertisementName, show.toString().toLowerCase())
  },

  clearShowAdvertisement() {
    window.sessionStorage.removeItem(showAdvertisementName)
  }
}
