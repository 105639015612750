import { ReportWrapperConstructor } from './report-wrapper-base';

export function JobErrorMixin<TBase extends ReportWrapperConstructor>(Base: TBase): any {
  return class ReportWithJobError extends Base {
    /**
     * Get information about scan job error
     */
    getJobError() {
      const data = this.getAllReportsData();
      return data?.job?.reason;
    }

    /**
     * Get job error message
     */
    getJobErrorMessage() {
      const jobError = this.getJobError();
      let message = '';

      if (jobError === 'timeout') {
        message = 'Scan process was interrupted due to a processing request timeout.';
      } else if (jobError === 'wait_in_queue_timeout') {
        message = 'Scan was cancelled due to waiting for too long in queue.';
      } else if (jobError) {
        message = 'Scan process was interrupted due to an internal error.';
      }

      return message;
    }

    /**
     * Get information about scan job error for specific report
     */
    getReportJobError(reportId: string) {
      const report = this.getReport(reportId);
      return report?.job?.reason;
    }

    /**
     * Get job error message for specific report
     */
    getReportJobErrorMessage(reportId: string) {
      const jobError = this.getReportJobError(reportId);
      return jobError ? 'Scan process was interrupted due to an internal error.': '';
    }

    /**
     * Get warnings produced by scan engine
     */
    getScanEngineWarnings(reportId: string) {
      const report = this.getReport(reportId);
      return report?.taskWarnings;
    }
  };
}
