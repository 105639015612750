import React, { useContext, useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { LogoImage } from '../logo'
import { LogoImageType } from 'app/modules/admin/features/settings/appearence/types'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { useLocation } from 'react-router'
import { ExtraButton } from './extra-button'
import { LanguageSelector } from './language'
import { UserButton } from './user-button/user-button'
import Search from './search-bar'
import Feed from './feed'
import { getActiveRouteClass } from './helpers/helpers'
import { togglePageScroll } from 'app/modules/shared/helpers'
import { applicationConfiguration, routeState } from 'app/modules/shared'
import { MobileMenuCloseButton, MobileMenuOpenedOverlay } from './mobile-assets'
import { Link, useNavigate } from 'react-router-dom'
import { getPageUrl } from 'app/modules/shared/routing'

const debounce = (func: (this: any, ...args: any[]) => any, wait: number = 0, immediate: boolean = false) => {
  let timeout: NodeJS.Timeout | null

  return function (this: any, ...args: any[]) {
    const onTimeout = () => {
      timeout = null
      if (!immediate) func.apply(this, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout as NodeJS.Timeout)
    timeout = setTimeout(onTimeout, wait)

    if (callNow) func.apply(this, args)
  }
}

const Appbar = () => {
  const { _t } = useTypedTranslation()
  const pageState = useContext(routeState)
  const navigate = useNavigate()
  const config = useContext(applicationConfiguration)

  const [isMenuExpanded, setMenuExpanded] = useState(false)
  const [isNavbarVisible, setNavbarVisible] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [hasNavbarUnder, setHasNavbarUnder] = useState(false)

  const currentRoute = useLocation().pathname.substring(1)
  const getActiveClass = (activeRoutes: string[]) => getActiveRouteClass(activeRoutes, currentRoute, 'appbar-item-selected')

  const scrollThreshold = 50
  const isCommunity = config.buildType === 'community'

  const handleToggle = () => setMenuExpanded(!isMenuExpanded)

  // Handler for hiding appbar on scroll
  const handleScroll = debounce(() => {
    const currentScrollPos = window.scrollY

    setNavbarVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > scrollThreshold) ||
      currentScrollPos < 10)

    setPrevScrollPos(currentScrollPos)
  }, 100)

  const handleBackSwipe = (event: PopStateEvent) => {
    if (isMenuExpanded) {
      event.preventDefault()
      handleToggle()
      // Block the browser from going back to the previous history point
      navigate(0)
    }
  }

  // Handle hiding appbar on scroll and closing mobile menu with backswipe
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('popstate', handleBackSwipe)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('popstate', handleBackSwipe)
    }
  }, [prevScrollPos, isNavbarVisible, handleScroll])

  // Detect if we have additional navbar under main, for possible changes in appbar styles
  useEffect(() => {
    setTimeout(() => {
      const isReportPage = ['report', 'report-new'].includes(pageState.data.page?.key as string)
      setHasNavbarUnder(isReportPage)
    }, 0);
  }, [pageState.data.page?.key])

  // Disable page scroll if menu on mobile is opened, and enable it back if closed
  useEffect(() => {
    togglePageScroll(!isMenuExpanded)

    return () => togglePageScroll(true)
  }, [isMenuExpanded])

  return (
    <div className="d-block w-100 mb-3" style={{height: '60px'}}>
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={isMenuExpanded}
        className={`appbar ${!isNavbarVisible ? 'appbar-mobile-hidden' : ''} ${hasNavbarUnder ? 'has-sub-navbar' : ''}`}
      >
        <Container fluid className="appbar-container">
          <Navbar.Brand href="scan" aria-label="Logo - Scan">
            <LogoImage type={LogoImageType.TopMenu} className="logo appbar-logo" />
          </Navbar.Brand>
          <div className="appbar-icons-wrapper">
            <div className="hide-desktop">
              <Search />
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" aria-label="Toggle menu" onClick={handleToggle}>
              {isMenuExpanded ?
                <i className="ds3-icon ds3-chevron-up appbar-toggler-icon" /> :
                <i className="ds3 ds3-bars appbar-toggler-icon" />
              }
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse className="appbar-collapse" id="responsive-navbar-nav">
            <Nav className="h-100">
              <div className={`appbar-item-wrapper ${getActiveClass(['reports'])}`}>
                <Link className='appbar-item nav-link' to={getPageUrl('all-reports') as string}>{_t('reports')}</Link>
              </div>
              <div className={`appbar-item-wrapper ${getActiveClass(['trends'])}`}>
                <Link className="appbar-item nav-link" to={getPageUrl('trends') as string}>{_t('trends')}</Link>
              </div>
              <div className={`appbar-item-wrapper ${getActiveClass(['advanced-search'])}`}>
                <Link className="appbar-item nav-link" to={getPageUrl('advanced-search') as string}>{_t('hunting')}</Link>
              </div>
              <div className={`appbar-item-wrapper ${getActiveClass(['leaders'])}`}>
                <Link className="appbar-item nav-link" to={getPageUrl('leaders') as string}>{_t('leader-board')}</Link>
              </div>
              <div className={`appbar-item-wrapper ${getActiveClass(['api/docs'])}`}>
                <Nav.Link className="appbar-item" href="api/docs" target='_blank'>{_t('api')}</Nav.Link>
              </div>
              <Feed />
              {isCommunity ?
                <div className={`appbar-item-wrapper`}>
                  <Nav.Link className="appbar-item" href="https://filescanio.blogspot.com/" target='_blank'>{_t('blog')}</Nav.Link>
                </div> : null
              }
              <div className="my-auto appbar-divider" />
              <ExtraButton />
              <LanguageSelector />
              {/* <Notifications />  - To be added in phase 2*/}
              <UserButton />
              <div className="hide-mobile">
                <Search />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <MobileMenuOpenedOverlay isOpened={isMenuExpanded}/>
      <MobileMenuCloseButton isOpened={isMenuExpanded} toggleMenu={handleToggle}/>
    </div>
  )
}

export default Appbar
