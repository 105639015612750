import { useLocation } from 'react-router-dom';

/**
 * Returns URL search params
 */
export function useURLSearchParams(): URLSearchParams {
  const queryString = useLocation().search;

  return new URLSearchParams(queryString);
}

export function useQuery(key: string): string | null {
  const params = useURLSearchParams();
  return params.get(key);
}