import { HttpService } from './http.service'
import { apiRoutes } from '../routing/api-routes'
import { HealthcheckInfo } from '../types/healthcheck-info'

export const StatusService = {
  getHealthcheckInfo: (days: number, full: boolean): Promise<HealthcheckInfo[]> => {
    return HttpService.get(full ? apiRoutes.queryFullHealthcheck : apiRoutes.queryHealthcheck + "?days=" + days)
  },
  getCurrentHealthcheckInfo: (): Promise<HealthcheckInfo> => {
    return HttpService.get(apiRoutes.doHealthcheck)
  }
}
