import Axios from 'axios';
import { AuthContextData } from '../context'
import { tokensStorage } from '../helper/storage'
import { apiRoutes } from '../../shared/routing'
import { NotificationData } from '../../shared/context/main-notification-context'
import { ApplicationConfiguration } from '../../shared/types'
import { doSignout } from '../../../modules/auth/helper/signout'

/**
 * Refresh access token if it is expired
 */
export function getRefreshTokenHttpInterceptor(
  authHandler: AuthContextData,
  mainNotification: NotificationData,
  config: ApplicationConfiguration
): [undefined, (error: any) => Promise<any>] {
  return [
    undefined,
    (error: any) => refreshTokens(error, authHandler, mainNotification, config)
  ]
}

/**
 * Make sure we only have one refresh token request at the same time
 */
let promiseRefreshToken: Promise<void> | undefined = undefined

/**
 * Perform token refresh if some request ended with 401 not authenticated response
 */
export async function refreshTokens(
  error: any,
  authHandler: AuthContextData,
  mainNotification: NotificationData,
  config: ApplicationConfiguration
) {
  if (error?.response?.status !== 401) {
    return Promise.reject(error)
  }

  const originalRequest = error.config
  if (originalRequest.url.indexOf(apiRoutes.refresh) === 0) {
    // If refresh request is not successfull, then refresh token is obsolete
    // and user needs to sign in again

    doSignout(authHandler, config)
    mainNotification.setMessageData('Your session has expired. Please sign in again.', 'info')

    // Return some default response that shouldn't crash any API call
    return Promise.resolve([])
  } else if (originalRequest.url.indexOf(apiRoutes.signout) === 0) {
    // It's ok to receive not authenticated code for signout request

    return Promise.resolve()
  }

  const refreshToken = tokensStorage.getRefreshToken()
  const doRefresh = refreshToken && !originalRequest._retry
  if (!doRefresh) {
    // Raise, to allow page controllers to handle Unauthorized exception correctly
    return Promise.reject(error)
  }

  originalRequest._retry = true

  if (promiseRefreshToken === undefined) {
    promiseRefreshToken = authHandler.refreshTokens()
  }

  await promiseRefreshToken
  promiseRefreshToken = undefined

  const accessToken = tokensStorage.getAccessToken()
  if (accessToken) {
    originalRequest.headers['Authorization'] = 'Bearer ' + accessToken
    return Axios(originalRequest)
  }

  // No access token here means refresh token got expired
  // and auto-signout performed during refresh request
  return Promise.resolve([])
}
