import React, { FormEvent, useRef, useState } from 'react'
import { Button, Form, FormControl, Nav } from 'react-bootstrap'
import { createReportsSearchLink } from 'app/modules/reports/helpers'
import { useNavigate } from 'react-router'
import TextInput from '../text-input'

const Search = () => {
  const [isSearchBarOpen, setSearchBarOpen] = useState<boolean>()
  const [query, setQuery] = useState('')

  const buttonRef = useRef(null)
  const navigate = useNavigate()

  const toggleSearchBar = () => {
    setQuery('')
    setSearchBarOpen(!isSearchBarOpen)
  }

  const submitSearch = (evt: FormEvent) => {
    evt.preventDefault()

    setQuery('')
    setSearchBarOpen(false)
    const searchLink = createReportsSearchLink({query})
    navigate(searchLink)
  }

  return (
    <div className="appbar-item-wrapper search-wrapper" ref={buttonRef} >
      {isSearchBarOpen ? (
        <div className="search-bar">
          <Form className="search-form" onSubmit={submitSearch}>
            <TextInput 
              placeholder="File name, URL, IP, Domain or Hash"
              className="search-bar-input"
              value={query}
              onChange={(evt) => setQuery((evt.target as HTMLInputElement).value)}
              clearable
              postfix={
                <Button
                  type="submit"
                  variant="tertiary"
                  aria-label="Submit search"
                  disabled={!query.length}
                >
                  <i className="ds3-icon ds3-search ds3-hover" />
                </Button> 
              }
            />
          </Form>
          <div className="search-toggle">
            <Nav.Link className="ps-0 pb-0 pt-1" onClick={toggleSearchBar} aria-label='Toggle search' >
                <i className="ds3-icon ds3-chevron-right color-text-main" />
            </Nav.Link>
          </div>
        </div>
      ) : (
        <div className="appbar-item search-toggle ds3-hover">
          <Nav.Link className="py-0" onClick={toggleSearchBar} aria-label='Toggle search' >
            <i className="ds3-icon ds3-search" />
          </Nav.Link>
        </div>
      )}
    </div>
  )
}

export default Search
