import React, { useContext } from 'react'
import { AdminAppbar } from '../../modules/admin/components/navbar/main'
import { AdminFeatureNavbar } from '../../modules/admin/components/navbar/navbar'
import { Footer } from '../footer'
import { MainNotification } from '../main-notification'
import { applicationConfiguration } from 'app/modules/shared'

/**
 * Layout for admins funtionality
 */
export function AdminsLayout({children}: {children: JSX.Element | JSX.Element[]}): JSX.Element {
  const config = useContext(applicationConfiguration)

  return (
    <>
      <AdminAppbar />
      <AdminFeatureNavbar />
      <MainNotification />

      <div className="container-fluid main-container">
        {children}
      </div>

      {config.showFooter ? <Footer /> : null}
    </>
  )
}
