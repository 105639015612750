import { modalContext } from "app/modules/shared"
import React, { useContext, useMemo } from "react"
import { useExport } from "../../contexts"
import { useTypedTranslation } from "app/modules/shared/hooks/use-translation"
import { ObjectRows, capitalize, has } from "../../helpers"
import { CommonLabeledDataRow, Copy2Clipboard, LongText, buildLongText } from "../../components"
import { FileContentModalViewer, ViewerButtonType } from "../../components/file-content-viewer/file-content-viewer"
import ReactJson from "react-json-view"
import { useTheme } from "app/modules/shared/context/theme-context"
import { CardHeaderNameValueCol } from "app/components"
import { EmulationCategory, EmulationItem, EmulationProcessType, ProcessListType } from "./types"
import { actionsCategories, actionsCategoriesIcons, defaultCategory, defaultIcon } from "./action-category"
import { ReportFilterValues } from "../../components/filter/types"
import { filter } from "lodash"
import { listToObj } from "app/modules/shared/helpers"

/**
 * Build additional description for collpased block
 */
export function getSecondaryText(item: { [key: string]: any }, exporting: boolean) {
  if (!item.additionalInformation) {
    return null
  }

  const limit = 40
  const additionalInfo = item.additionalInformation
  const isAPIAction = (
    (item.action === 'CallAPI' || item.action === 'LocateAPIProc') &&
    additionalInfo.Library &&
    (additionalInfo.Alias || additionalInfo.Entrance)
  )

  if (isAPIAction) {
    const name = item.action === 'CallAPI' ? 'Call API' : 'Locate API Proc'
    const value = `${additionalInfo.Library}@${additionalInfo.Entrance ?? additionalInfo.Alias}`
    if (exporting) {
      return value
    }

    return (
      <CardHeaderNameValueCol
        name={name}
        value={<LongText value={value} limit={limit} copyable={false} className="ellipsis"/>}
      />
    )
  }

  const keyItems: string[] = [
    'Address', 'Alias', 'Class', 'Url', 'URI', 'Command',
    'Program', 'ProcessName', 'Path', 'Event', 'Section',
    'CodeModule', 'Dir', 'Duration', 'Language'
  ]
  for (const key of keyItems) {
    if (additionalInfo[key]) {
      return (
        <CardHeaderNameValueCol
          name={key}
          value={<LongText value={additionalInfo[key]} limit={limit} copyable={false} className="ellipsis"/>}
        />
      )
    }
  }

  return null
}

/**
 * Build components for item additional info
 * @param additionalInfo
 */
export function AdditionalInfo({ additionalInfo }: { additionalInfo: null | { [key: string]: any } }) {
  const { _t } = useTypedTranslation()
  if (!additionalInfo) {
    return null
  }

  const codeItems: string[] = ['Command', 'CommandLine', 'Content']
  const data: { [key: string]: any } = { ...additionalInfo }

  if (typeof data.Signature !== 'undefined') {
    data.Signature = <DeepNestedData data={data.Signature} collapsed={2} />
  }

  codeItems.forEach(name => {
    if (has(data, name)) {
      data[name] = (
        <div className="d-flex">
          <div className="extracted-code extractd-code-area text-main color-text-main">{data[name]}</div>
          <div className="show-hidden-icons">
            <Copy2Clipboard copyText={data[name]}/>
          </div>
        </div>
      )
    }
  })

  if (data.Arguments && Array.isArray(data.Arguments)) {
    data.Arguments = data.Arguments.map((
      item: { name: string, value: string | any[] } | { name: string, value: string | any[] }[],
      idx: number
    ) => {
      if (Array.isArray(item)) {
        return (
          <div className='p-2 d-block'>
            {item.map((row: { name: string, value: string | any[] }) => {
              const label = capitalize(row.name)
              const value = row.value

              return (
                <CommonLabeledDataRow
                  key={`${row.name}-${idx}`}
                  label={label}
                  value={value}
                />
              )
            })}
          </div>
        )
      } else {
        const label = capitalize(item.name)
        const value = item.value

        return (
          <CommonLabeledDataRow
            key={`${item.name}-${idx}`}
            label={label}
            value={value}
          />
        )
      }
    })
  }

  setBase64View(data, 'Data', _t('data'))
  setBase64View(data, 'Bin', _t('bin'))

  return <ObjectRows object={data}/>
}

type MetaDataItemProps = {
  meta: { [key: string]: any };
}
export function MetaDataItem(props: MetaDataItemProps) {
  const { meta } = props
  const keys = Object.keys(meta)
  if (keys.includes('reason') && keys.length === 1) {
    return null
  }

  return <DeepNestedData name="MetaData" data={meta} collapsed={false} />
}

function DeepNestedData({ name, data, collapsed = true }: { name?: string, data: { [key: string]: any }, collapsed?: boolean | number }) {
  const { mode } = useTheme();
  const JsonComponent = ReactJson as any
  return (
    <div className='data-block'>
      <div className='data-row d-flex'>
        {name && <div className="label">{name}: </div>}
        <JsonComponent
          src={data}
          displayDataTypes={false}
          collapsed={collapsed}
          name={null}
          theme={mode === 'dark' ? 'summerfruit' : 'summerfruit:inverted'}
          style={{ backgroundColor: 'transparent' }}
        />
      </div>
    </div>
  )
}

function setBase64View(data: { [key: string]: any }, key: string, labelText: string) {
  const base64Regexp = /^(?:[0-9a-zA-Z+/]{4})*(?:(?:[0-9a-zA-Z+/]{2}==)|(?:[0-9a-zA-Z+/]{3}=))?$/
  const isBase64 = (
    data[key] && typeof data[key] === 'string'
    && data[key].match(base64Regexp)
  )

  if (isBase64) {
    data[key] = {
      component: <Base64View value={data[key]} language={data.language ?? data.Language} />,
      label: (
        <>
          {labelText} (Base64):
        </>
      ),
      float: true
    }
  }
}

function Base64View({ value, language }: { value: string, language?: string }) {
  const { _t } = useTypedTranslation()
  const decoded = useMemo(() => {
    return atob(value)
  }, [value])

  return (
    <div className='base64-container'>
      <p className='clamp clamp-5 mb-1'>
        {value}
      </p>
      <div className='show-hidden-icons d-flex gap-2'>
        <Copy2Clipboard copyText={value} className="ms-0"/>
        <FileContentModalViewer
          content={decoded}
          button={{ text: _t('view-original-data'), type: ViewerButtonType.Lock }}
          modalTitle={_t('original-data')}
          allowPlainTextHex={false}
          language={language?.toLowerCase()}
        />
      </div>
    </div>
  )
}

export function ProcessList({data}: {data: ProcessListType}) {
  return (
    <table className="table left-aligned">
      <thead>
        <th>PID</th>
        <th>ParentPID</th>
        <th>Program</th>
        <th>Command</th>
      </thead>
      <tbody>
        {Object.keys(data).map((pid) => {
          const item: EmulationProcessType = data[pid]

          return (
            <tr>
              <td>{pid}</td>
              <td>{item.ParentPid}</td>
              <td>{item.Program}</td>
              <td><LongText value={item.Command} limit={64}/></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export function getActionIconData(action: string) {
  for (const category in actionsCategories) {
    const data = actionsCategories[category as EmulationCategory]

    if (typeof data[action as keyof typeof data] !== 'undefined') {
      return {
        iconImageClass: data[action as keyof typeof data],
        iconCategoryClass: actionsCategoriesIcons[category as EmulationCategory]
      }
    }
  }

  return {
    iconImageClass: defaultIcon,
    iconCategoryClass: actionsCategoriesIcons.OtherEventsAndActions
  }
}

export function getActionCategory(action: string): EmulationCategory {
  for (const category in actionsCategories) {
    const data = actionsCategories[category as EmulationCategory]

    if (typeof data[action as keyof typeof data] !== 'undefined') {
      return category as EmulationCategory
    }
  }

  return defaultCategory
}

export function applyFilter(items: EmulationItem[], filterValues: ReportFilterValues | undefined) {
  if (!Object.keys(filterValues || []).length) {
    return items
  }

  const filter = filterValues as ReportFilterValues
  const categoryFilter = listToObj(filter.category)
  const typeFilter = listToObj(filter.type)
  const sourceFilter = listToObj(filter.source)
  const query = (filter.search?.[0] || '').toLocaleLowerCase()

  const result: EmulationItem[] = []

  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const skip = (
      (filter.category?.length && typeof(categoryFilter[item.category]) === 'undefined') ||
      (filter.type?.length && typeof(typeFilter[item.action]) === 'undefined') ||
      (filter.source?.length && typeof(sourceFilter[item.source]) === 'undefined') ||
      (
        query &&
        (item.action.toLowerCase().indexOf(query) === -1) &&
        (item.category.toLowerCase().indexOf(query) === -1) &&
        (!item.description || item.description.toLowerCase().indexOf(query) === -1) &&
        (!item.commandLowerForFilter || item.commandLowerForFilter.indexOf(query) === -1) &&
        (
          !item.additionalInformation?.Comments?.length ||
          !item.additionalInformation?.Comments.filter(
            (comment: string) => comment.toLocaleLowerCase().indexOf(query) !== -1
          ).length
        )
      )
    )

    if (!skip) {
      result.push(item)
    }
  }

  return result
}

// Simplify id of emulation item
export function formatId(dataUUID: string) {
  return dataUUID ? dataUUID.replace(/-/g, '') : ''
}
