import { EmulationCategory } from "./types"

export const defaultIcon = 'category-default'
export const defaultCategory =  EmulationCategory.OtherEventsAndActions

export const actionsCategories = {
  [EmulationCategory.Execution]: {
    'Shell': 'shell',
    'ExecQuery': 'file-lookup',
    'InitWMIObject': defaultIcon,
    'LoadCreatedDll': 'upload',
    'OpenWordDocument': 'folder-open',
    'OpenWorkbook': 'folder-open',
    'OpenPresentation': 'folder-open',
    'OpenPublisherDocument': 'folder-open',
    'AppActivate': defaultIcon,
    'ExecuteScript': 'script',
    'PowerShell': 'shell',
    'Retry': 'refresh',
    'Eval': defaultIcon,
    'CallbackBinary': defaultIcon,
    'ProcHollow': defaultIcon,
    'ShellCode': 'shell',
    'StartProcess': 'start',
    'EvaluatePCode': defaultIcon,
    'LoadShadowVBAProject': 'upload',
    'ExecuteSchedulerJob': defaultIcon,
    'AddNetType': defaultIcon,
    'LoadNetAssembly': 'upload',
    'InvokeMethodOfLoadedAssembly': defaultIcon,
    'DllInjection': defaultIcon,
    'ExecuteService': defaultIcon
  },
  [EmulationCategory.FileManipulation]: {
    'WriteTextFile': 'file-text',
    'WriteBinaryFile': 'file-blank',
    'CopyFile': 'copy',
    'CopyFolder': 'copy',
    'MoveFolder': 'file-export',
    'DeleteFile': 'remove',
    'ShellCreatedFile': 'shell',
    'PasteOLE': 'copy',
    'CopyProgram': 'copy',
    'MoveFile': 'file-export',
    'MoveProgram': 'file-export',
    'CopyEntry': 'copy',
    'RemoveFolder': 'remove',
    'SaveAs': 'save',
    'CreateFolder': 'add',
    'CallCreatedDll': defaultIcon,
    'RenamePath': 'edit',
    'WriteConfig': 'edit',
    'ModifyNormalTemplate': 'edit',
    'ModifyCodeModule': 'edit',
    'AddWorkbook': 'add',
    'AddDocument': 'add',
    'CreateFile': 'add',
    'CreateShortcut': 'add'
  },
  [EmulationCategory.Network]: {
    'HttpRequest': 'http',
    'AccessBadUrl': 'link',
    'OpenUrl': 'link',
    'ConnectNetwork': defaultIcon,
    'SendNetworkData': defaultIcon,
    'ReceiveNetworkData': defaultIcon,
    'DnsLookup': 'search',
    'IpLookup': 'search'
  },
  [EmulationCategory.DecisionPoint]: {
    'Sleep': 'eye-closed',
    'UseEnvironmentVariableInCondition': defaultIcon,
    'SuggestEnvironCondition': defaultIcon,
    'CompareEnvironmentVariable': defaultIcon,
    'ExitInfiniteLoop': 'process'
  },
  [EmulationCategory.OtherEventsAndActions]: {
    'EvalFormula': 'formula',
    'LocateAPIProc': 'settings',
    'CallAPI': 'settings',
    'RegisterXLL': defaultIcon,
    'VirtualProtect': defaultIcon,
    'Event': 'calendar',
    'Schedule': 'calendar',
    'BindKey': 'key-bind',
    'CloseVirusProtection': defaultIcon,
    'ReadRegistry': 'eye',
    'ReadConfig': 'eye',
    'WriteRegistry': 'edit',
    'DeleteRegistry': 'remove',
    'SuspiciousText': 'text-suspicious',
    'Email': 'email',
    'TypeText': defaultIcon,
    'ReadBigFile': 'eye',
    'ConnectDB': 'database',
    'SendChar': defaultIcon,
    'WindowCommand': defaultIcon,
    'CloseWindow': "close",
    'FindWindow': 'search',
    'SendKeys': 'forward',
    'AccessEnvironmentVariable': 'unlock',
    'StoreEnvironmentVariable': 'save',
    'SuspiciousAccess': 'warning-triangle',
    'EmbedScript': 'script',
    'EmbedPackage': 'package',
    'EmbedHtml': 'screen',
    'EmbedOfficeFile': 'file-blank',
    'RegExp': defaultIcon,
    'EmbedEquation': 'formula',
    'EventTrigger': 'target',
    'CreateObject': 'add',
    'DecodeEncodedScript': 'script',
    'AccessStdStream': 'unlock',
    'AccessLocale': 'unlock',
    'InstallProduct': defaultIcon,
    'SetCellValue': defaultIcon,
    'OfficeFileInformation': 'file-blank',
    'LoadBitmap': 'upload',
    'SetCOMImpersonationLevel': 'package',
    'DDE': defaultIcon,
    'UnZipFile': 'package-up',
    'CompressArchive': 'package',
    'SuggestFileExtension': defaultIcon,
    'MsSearch': 'search',
    'ConfigureWindowsDefender': defaultIcon,
    'InconsistentMimeType': defaultIcon,
    'RegisterService': defaultIcon,
    'ScreenShot': 'image',
    'RewriteExit': defaultIcon,
    'MountNetDisk': defaultIcon
  }
}

export const actionsCategoriesIcons = {
  [EmulationCategory.Execution]: 'blue',
  [EmulationCategory.FileManipulation]: 'raspberry',
  [EmulationCategory.Network]: 'teal-blue',
  [EmulationCategory.DecisionPoint]: 'purple',
  [EmulationCategory.OtherEventsAndActions]: 'grey'
}
