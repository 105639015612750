import BaseFormatter from './base'
import { has, hasFields } from '../../helpers'

/**
 * Format report response data for MSI file details page
 */
export default class MsiDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}
    const resource = this.getResource(data, 'file')
    if (!resource) return

    if (!has(resource, 'msiParserMetadata') && !has(resource, 'msiParserTables')) return

    this.copyFromResource(resource, result)

    if (hasFields(result)) {
      report.msi_details = { ...report.details, ...result }
      report.msi_details.overview = { ...report.details.overview, ...result.overview }
      delete report.details
    }
  }

  copyFromResource(data: { [key: string]: any }, result: { [key: string]: any }): void {
    this.copyFields(['msiParserMetadata', 'msiParserTables', 'certInfos'], data, result)
  }
}