import { AxiosRequestConfig } from 'axios';
import { HttpService } from '../../shared/services'
import { apiRoutes } from '../../shared/routing/api-routes'

export const searchService = {
  search: (params: string): Promise<any> => {
    return HttpService.get(apiRoutes.reportSearch + `?` + params)
  },
  advancedSearch: (params: string): Promise<any> => {
    return HttpService.get(apiRoutes.advancedSearch + `?` + params)
  },
  getPrevalence: (params: any, days: number | string, exclude_report_ids: string): Promise<any> => {
    return HttpService.post(apiRoutes.getPrevalence, { ...params, days }, { params: { exclude_report_ids }})
  },
  getSimilarReports: (params: { [key: string]: string }, days: number, exclude_report_ids: string): Promise<any> => {
    return HttpService.get(apiRoutes.getSimilarReports, {
      params: { ...params, days, exclude_report_ids }
    })
  },
  downloadIOCs: async (params: string, pageNum?: number, pageSize?: number): Promise<any> => {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      headers: {
        'page': `${pageNum || 0}`,
        'pageSize': `${pageSize || 0}`
      }
    }

    const data = await HttpService.get<any>(apiRoutes.downloadIOCs + '?' + params, config);
    const link = window.URL.createObjectURL(new Blob([data]));
    const elem = document.createElement('a');
    elem.href = link;
    elem.setAttribute('download', 'iocs.csv')
    document.body.appendChild(elem);
    elem.click();
    return HttpService.get(apiRoutes.downloadIOCs + '?' + params)
  },
  getReputation: (iocType: string, iocValue: string): Promise<any> => {
    const param = iocType === 'hash' ? 'sha256' : 'ioc_value'
    const url = apiRoutes.getReputation.replace('{iocType}', iocType)
    return HttpService.get(`${url}?${param}=${iocValue}`)
  }
};
