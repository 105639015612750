import React from "react"
import { useTypedTranslation } from "app/modules/shared/hooks/use-translation"
import { ObjectRows } from "../../helpers"
import { useExport } from "../../contexts"

export function DefaultOverviewBySections({data}: {[key: string]: any}) {
  const { _t } = useTypedTranslation()
  const exporting = useExport()
  const overview = {...data}
  const grouped: {[key: string]: any} = {
    'file-details': {},
    'hashes': {},
    'visualization': {}
  }

  const hashes = [
    'MD5', 'SHA-1', 'SHA-256', 'SHA-512', 'imphash',
    'fsiofuzzyhash', 'authentihash', 'ssdeep'
  ]
  for (let name of hashes) {
    if (typeof overview[name] !== 'undefined') {
      grouped['hashes'][name] = overview[name]
      delete overview[name]
    }
  }

  if (typeof overview.visualization !== 'undefined') {
    grouped.visualization.image = overview.visualization
    delete overview.visualization
  }

  grouped['file-details'] = overview

  return (
    <div className='overview-by-sections'>
      {Object.keys(grouped).map((groupName, idx) => {
        const values = grouped[groupName]
        if (!Object.keys(values).length) {
          return null
        }

        return (
          <React.Fragment key={idx.toString()}>
            {groupName !== 'file-details' ? <h2 className="h3 mb-4">{_t(groupName)}</h2> : null}
            <ObjectRows
              object={values}
              enableCopy={false}
              checkHash={!exporting}
              lengthLimit={64}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}
