/**
 * Build filter to fetch specific report parts
 */
export class ReportFilter {
  /**
   * Report fields to fetch
   */
  fields: string[] = [
    // custom general fields
    'general',

    // overview
    'allSignalGroups',
    'allTags',

    // scan progress
    'overallState',
    'taskWarnings',
    'positionInQueue',
    'taskReference',
    'subtaskReferences',
    'interestingScore',

    'finalVerdict',

    // 'f:extractedFiles.yaraMatches', - to be implemented
    // 'fd:fileDownloadResults.yaraMatches' - to be implemented

    'fd:fileDownloadResults',
    'fd:extractedUrls',
    'dr:domainResolveResults',
    'v:visualizedSample.compressedBase64',
    'v:renderedImages',
    'wi:whoisLookupResults',
    'ur:renderResults',

    'f:all', // fetch whole `file` resource - needed for lots of subpages
    'o:all' // whole OSINT resources (there might be few of them)
  ]

  /**
   * Sort options to apply to fetched fields
   */
  sorting: { [field: string]: string } = {
    'allSignalGroups': 'description:asc,allMitreTechniques:desc,averageSignalStrength:desc',
    'allOsintTags': 'tag.name:asc',
    'f:disassemblySections': 'levelOfInformation:desc',
    'f:extendedData.importsEx': 'module.suspicious:desc',
    // 'f:extractedFiles.strings.references': 'str:asc.interesting:desc' //,metaData:desc:exists
  }

  /**
   * Convert fields to GET query string
   */
  toQueryParams(): string {
    const params: string[] = [];

    this.fields.forEach(field => {
      params.push('filter=' + encodeURIComponent(field))
    })

    Object.keys(this.sorting).forEach((field: string) => {
      const options = this.sorting[field]
      const value = `${field}(${options})`

      params.push('sorting=' + encodeURIComponent(value))
    })

    return params.join('&')
  }
}
