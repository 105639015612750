import React from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { Trans } from 'react-i18next'

export default function QuotaExceededPage(): JSX.Element {
  const { _t } = useTypedTranslation()
  return (
    <div className="centered-layout error-page">
      <div className="error-box">
        <div className="error-code">429</div>
        <div className="error-message">
          {_t('too-many-requests-note1')}
        </div>
        <div className="error-note text-muted text-center">
          {_t('too-many-requests-note2')}<br/>
          <Trans i18nKey="too-many-requests-note3">
            Try our <a href='https://info.opswat.com/a-next-generation-sandbox' target='_blank' rel='noreferrer'>advanced malware analysis solution</a><br/> and unlock the full power.
          </Trans>
        </div>
      </div>
    </div>
  )
}
