import React from 'react'
import { Alert } from 'react-bootstrap'

export function AlertDismissible(
  {type, title, content, show, children, className}:
  {type: string, title?: string, content: string | JSX.Element, show?: boolean, children?: any, className?: string}
): JSX.Element {

  return (
    <Alert show={show} variant={type} className={className || ''}>
      <div>
        {title ? <div className="important">{title}</div> : null}
        {content}
        {children}
      </div>
    </Alert>
  )
}
