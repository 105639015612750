import React, { ButtonHTMLAttributes } from "react"
import { ButtonProps } from "./types"
import { Tooltip } from "app/modules/reports/components/tooltip"

// General button component
const Button = React.forwardRef((props: ButtonProps, ref) => {
  const {children, className, variant = 'primary', disabled, tooltip, tooltipPlacement, type, ...other} = props
  const variantClass = 'btn-' + variant
  const fullClass = `btn ${variantClass} ${disabled ? 'disabled' : ''} ${className || ''}`
  const buttonProps = other as unknown as React.ButtonHTMLAttributes<HTMLButtonElement>

  const button = (
    type === 'submit' ?
    <button className={fullClass} ref={ref as any} {...buttonProps} >{children}</button> :
    <a className={fullClass} ref={ref as any} {...other} >{children}</a>
  )

  return !tooltip ? button : (
    <Tooltip content={tooltip} placement={tooltipPlacement} id='button-tooltip'>
      {button}
    </Tooltip>
  )
})

export default Button
