import BaseFormatter from './base'
import { VisualizationData } from '../../types'

/**
 * Prepare visualization images
 */
export default class VisualizationFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  async format(data: { [key: string]: any }, report: { [key: string]: any }): Promise<void> {
    const resource = this.getResource(data, 'visualization')
    if (resource) {
      report.visualization = resource.visualizedSample as VisualizationData
    }
  }
}
