import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from './components';
import { initPages } from './modules/shared/routing/pages-data'
import { to } from './modules/shared/routing/render-page'
import { AppWrappers } from './components/app-wrappers'
import './modules/shared/events/register-events'
import './app.scss';
import NotFoundPage from './components/errors/not-found';

// Set routing options for all pages
initPages()

export const App = (): JSX.Element => {
  return (
    <AppWrappers>
      <Router>
        <ErrorBoundary>
          {/* Switch for routes. Routes should be immediate kids of it. */}
          <Routes>
            {/* Admin */}
            <Route {...to('admin-home')} />
            <Route {...to('admin-user-management-home')} />
            <Route {...to('admin-user-management')} />
            <Route {...to('admin-user-management-item')} />
            <Route {...to('admin-settings-home')} />
            <Route {...to('admin-settings')} />
            <Route {...to('admin-stat-home')} />
            <Route {...to('admin-stat')} />
            <Route {...to('admin-jobs-home')} />
            <Route {...to('admin-jobs')} />
            <Route {...to('admin-scan-sources-home')} />
            <Route {...to('admin-scan-sources')} />
            <Route {...to('admin-audit-logger-home')} />
            <Route {...to('admin-audit-logger')} />

            {/* Auth */}
            <Route {...to('first-signup-redirect')} />
            <Route {...to('invite-signup-redirect')} />
            <Route {...to('sign-up')} />
            <Route {...to('sign-up-invite')} />
            <Route {...to('sign-up-confirm')} />
            <Route {...to('sign-in')} />
            <Route {...to('oauth-redirect')} />
            <Route {...to('request-password-reset')} />
            <Route {...to('reset-password')} />

            {/* User related */}
            <Route {...to('user-profile')} />

            {/* Report related */}
            <Route {...to('home')} />
            <Route {...to('scan')} />
            <Route {...to('upload-initial-page')} />
            <Route {...to('report-overview-redirect')} />
            <Route {...to('report-new-overview-redirect')} />
            <Route {...to('report')} />
            <Route {...to('report-new')} />
            <Route {...to('my-reports')} />
            <Route {...to('all-reports')} />
            <Route {...to('search-result')} />
            <Route {...to('advanced-search')} />
            <Route {...to('threats-overview')} />
            <Route {...to('trends')} />
            <Route {...to('leaders')} />

            {/* Help pages */}
            <Route {...to('help')} />
            <Route {...to('status')} />
            <Route {...to('terms')} />
            <Route {...to('privacy')} />
            <Route {...to('cookie-policy')} />
            <Route {...to('feed')} />
            <Route {...to('archives')} />

            {/* Custom error pages */}
            <Route {...to('quota-exceeded')} />

            {/* Not found, should be the last route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ErrorBoundary>
      </Router>
    </AppWrappers>
  );
};

export default App;
