import React from 'react'
import { Spinner } from 'react-bootstrap'
import { SearchParams } from '../../types'
import { createReportsSearchLink } from '../../helpers/functions'
import { Prevalence } from '../prevalence'
import { buildLongText } from '../long-text'
import { ModalWrapperType } from '../../../shared/types'

export function withSearch(
    isMain: boolean, checked: boolean, error: string, similars: { [key: string]: any },
    modalWrapper: ModalWrapperType, exporting: boolean, reportId: string,
    key: string, value: string, t: CallableFunction, label: any = null, copy = true
  ): { [key: string]: any } {
    const similarsData: { [key: string]: any } | undefined = (
      similars && similars[key] && Object.values(similars[key]).length ?
      Object.values(similars[key])[0] as { [key: string]: any } :
      undefined
    )

    let prevalence: any = null
    if (isMain) {
      if (!checked) {
        prevalence = (
          <span className='mx-2'>
            <span className='me-2 text-secondary'>{t('similar-checking')}</span>
            <Spinner size='sm' animation="border" variant='warning' />
          </span>
        )
      } else if (error) {
        prevalence = (
          <>
            <SearchIcon params={{[key]: value}}/>
            <span className="alert alert-warning alert-small ms-2">{error}</span>
          </>
        )
      } else {
        prevalence = (
          <span className='prevalence-item d-flex' title={key}>
            <Prevalence
              count={similarsData?.counts}
              type={key}
              value={value}
              context='similar'
              tooltip='hash-prevalence-tooltip'
              currentReportId={reportId}
            />
          </span>
        )
      }
    }

    const iconClass = prevalence ? 'show-hidden-icons' : ''

    const object: { [key: string]: any } = {
      component: (
        <span className={'d-inline-flex flex-wrap ' + iconClass}>
          {exporting ? value : buildLongText(value, modalWrapper, true, 32)}
          {exporting || prevalence}
        </span>
      ),
      copy: false
    }

    label && (object.label = label)
    return object
  }

  export function SearchIcon({params}: {params: SearchParams}) {
    return (
      <a href={createReportsSearchLink(params)} target='_blank' rel='noreferrer'>
        <i className='ds3-icon ds3-search ms-2 text-primary' style={{paddingTop: '1px'}}></i>
      </a>
    )
  }
