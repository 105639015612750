export enum ReportFilterSectionType {
  Verdict = 'verdict',
  Search = 'search',
  Range = 'range',
  Flags = 'flags',
  Flag = 'flag',
  Date = 'date',
  List = 'list',
  Files = 'files'
}

export type ReportFilterItem = {
  value: string
  name?: string
}

export type ReportFilterSection = {
  name: string
  type: ReportFilterSectionType
  items: ReportFilterItem[]
  title?: string
  placeholder?: string
  all?: boolean
  radio?: boolean
  nameDefault?: boolean
}

export type ReportFilterStat = {
  types: {
    [sectionName: string]: {
      [key: string]: number
    }
  },
  total: number
}

export type ReportFilterValues = {
  [sectionName: string]: string[]
}

export type ReportPageFilterSettings = {
  sections: ReportFilterSection[]
  stat: ReportFilterStat
  defaults?: ReportFilterValues
}

export type ReportPageMultiFileFilterSettings = {
  files: {
    [fileKey: string]: ReportPageFilterSettings
  }
}

export type ReportFilterSettings = {
  [pageName: string]: ReportPageFilterSettings | ReportPageMultiFileFilterSettings
}

export type ReportFilterApplied = {
  [pageName: string]: ReportFilterValues
}

export type ReportFilterContextData = {
  getValues: () => ReportFilterValues | undefined
  setValues: (values: ReportFilterValues) => void
  getFilterSettings: () => ReportPageFilterSettings | ReportPageMultiFileFilterSettings | undefined
  isFilterActive: boolean
  setFilterActive: (isActive: boolean) => void
  isFilterApplied: () => boolean
  init: (
    sections: ReportFilterSection[],
    stat: ReportFilterStat,
    defaults?: ReportFilterValues
  ) => void
  initNew: (settings: ReportPageFilterSettings | ReportPageMultiFileFilterSettings) => void
}
