import React, { ChangeEventHandler, useEffect, useState } from 'react'

const Switch = ({enabled, onChange, className, label, disabled}: {
  enabled?: boolean,
  onChange: ChangeEventHandler<HTMLInputElement>,
  className?: string,
  label?: string | JSX.Element,
  disabled?: boolean
}) => {
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    setChecked(!!enabled)
  }, [enabled])

  return (
    <div className={"form-check form-switch " + (className || '')} key={checked ? '1' : '0'}>
      <input type="checkbox" id="custom-switch" className="form-check-input" onChange={onChange} defaultChecked={checked} disabled={disabled}/>
      {label ? <label title="" htmlFor="custom-switch" className="form-check-label">{label}</label> : null}
    </div>
  )
}

export default Switch
