export type BaseUploadOptions = {
  description: string,
  password: string,
  isPassword: boolean,
  isPrivate: boolean,
  isPrivateReport: boolean,
  skipWhitelisted: boolean,
  showPropagate: boolean,
  propagateTags: boolean,
  isTerms: boolean,
  tags: string[],
}

export type ExtendedUploadOptions = {
  rapidMode: boolean,
  osint: boolean,
  extendedOsint: boolean,
  extractedFilesOsint: boolean,
  visualization: boolean,
  filesDownload: boolean,
  resolveDomains: boolean,
  inputFileYara: boolean,
  extractedFilesYara: boolean,
  whois: boolean,
  ipsMeta: boolean,
  imagesOcr: boolean,
  scanMode?: string
}

export const baseUploadOptionsDefaults = {
  description: '',
  password: '',
  isPassword: false,
  isPrivate: false,
  isPrivateReport: false,
  skipWhitelisted: false,
  showPropagate: false,
  propagateTags: true,
  isTerms: false,
  tags: []
}

export const extendedUploadOptionsDefaults: ExtendedUploadOptions = {
  rapidMode: false,
  osint: true,
  extendedOsint: true,
  extractedFilesOsint: true,
  visualization: true,
  filesDownload: true,
  resolveDomains: true,
  inputFileYara: true,
  extractedFilesYara: true,
  whois: true,
  ipsMeta: true,
  imagesOcr: true
}

export const extendedUploadOptionsDisabled: ExtendedUploadOptions = {
  rapidMode: false,
  osint: true,
  extendedOsint: true,
  extractedFilesOsint: true,
  visualization: true,
  filesDownload: true,
  resolveDomains: true,
  inputFileYara: true,
  extractedFilesYara: true,
  whois: true,
  ipsMeta: true,
  imagesOcr: true
}
