import { VisualMode } from 'app/modules/shared/types/theme';
import { AxiosRequestConfig } from 'axios';
import { HttpService } from '../../shared';
import { apiRoutes } from '../../shared/routing'
import { ScanSourceConfig } from '../features/scan-sources/types';
import { LogoImageType } from '../features/settings/appearence/types';
import { FormattedAdminSettings, ApiQuotaSetting } from '../types'
import { LicenseResponseType } from '../types/license-info';
import { NewsItemType } from '../features/settings/appearence/news/types';

export const settingsService = {
  get<T>(group: string): Promise<T> {
    const url = apiRoutes.settingsGroup.replace('{group}', group)

    return HttpService.get<T>(url)
  },

  post<T>(group: string, data: FormattedAdminSettings): Promise<T> {
    const url = apiRoutes.settingsGroup.replace('{group}', group)

    return HttpService.post<T>(url, data);
  },

  postOAuthServices<T>(data: FormattedAdminSettings): Promise<T> {
    return HttpService.post<T>(apiRoutes.saveOauthServices, data);
  },

  removeOAuthService<T>(key: string): Promise<T> {
    const url = apiRoutes.removeOAuthService.replace('{service}', key)
    return HttpService.post<T>(url);
  },

  getUserGroups<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.userGroups)
  },

  getApiQuota(): Promise<ApiQuotaSetting[]> {
    return HttpService.get<ApiQuotaSetting[]>(apiRoutes.apiQuota);
  },

  postApiQuota<T>(data: ApiQuotaSetting): Promise<T> {
    return HttpService.post<T>(apiRoutes.apiQuota, data);
  },

  removeApiQuota<T>(quotaId: string): Promise<T> {
    return HttpService.post<T>(apiRoutes.apiQuotaRemove.replace('{quotaId}', quotaId));
  },

  getConfig<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.appConfig)
  },

  getRetentionCalculation<T>(retention: {[key: string]: any}): Promise<T> {
    const params: string[] = []
    Object.keys(retention).forEach(name => {
      params.push(name.toLowerCase() + '=' + retention[name])
    })

    return HttpService.get<T>(apiRoutes.adminRetentionCalculation + "?" + params.join('&'))
  },

  getApiData<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.apiData)
  },

  generateExternalTokensSecret<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.generateExternalTokensSecret)
  },

  postScanSourceConfig<T>(data: ScanSourceConfig): Promise<T> {
    return HttpService.post<T>(apiRoutes.editScanSourceConfig, data);
  },

  getScanSourcesConfigs<T>(page: number | undefined, filter: string, sorting: string): Promise<T> {
    const params = []
    if (page) {
      params.push('page=' + page)
    }
    if (filter) {
      params.push('filter=' + encodeURIComponent(filter))
    }
    if (sorting) {
      params.push('sort=' + sorting)
    }

    const query = params.length ? '?' + params.join('&') : ''
    return HttpService.get<T>(apiRoutes.getScanSourcesConfigs + query)
  },

  getScanSourcesFiles<T>(
    page: number | undefined,
    config: string,
    state: string,
    verdict: string
  ): Promise<T> {
    const params = []

    // if (name) {
    //   params.push('name=' + encodeURIComponent(name))
    // }
    if (page) {
      params.push('page=' + page)
    }
    if (config) {
      params.push('config=' + config)
    }
    if (state) {
      params.push('state=' + state)
    }
    if (verdict) {
      params.push('verdict=' + verdict)
    }
    // if (sorting) {
    //   params.push('sort=' + sorting)
    // }

    const query = params.length ? '?' + params.join('&') : ''
    return HttpService.get<T>(apiRoutes.getScanSourcesFiles + query)
  },

  checkScanSourceConnection<T>(configId: string) {
    return HttpService.get<T>(apiRoutes.checkScanSourceConnection.replace('{configId}', configId))
  },

  getScanSourceSubjobs<T>(page: number | undefined, configId: string | undefined) {
    const params = []

    if (page) {
      params.push('page=' + page)
    }
    if (configId) {
      params.push('config=' + configId)
    }

    const query = params.length ? '?' + params.join('&') : ''
    return HttpService.get<T>(apiRoutes.getScanSourceSubJobs + query)
  },

  getLogos<T>() {
    return HttpService.get<T>(apiRoutes.listAllLogos)
  },

  selectLogo(logo: string | File, type: LogoImageType, theme: VisualMode) {
    const formData = new FormData()
    formData.append('type', type)
    formData.append('theme', theme)

    const logoKey = typeof logo === 'string' ? 'name' : 'file'
    formData.append(logoKey, logo)

    const config: AxiosRequestConfig = {headers: {'Content-Type': 'multipart/form-data'}}
    return HttpService.post(apiRoutes.selectLogo, formData, config)
  },

  getVersionInfo(): Promise<string> {
    return HttpService.get<string>(apiRoutes.getVersionInfo)
  },

  getLicenseInfo<LicenseResponseType>(): Promise<LicenseResponseType> {
    return HttpService.get<LicenseResponseType>(apiRoutes.getLicenseInfo)
  },

  activateLicenseOnline<T>(appServer: string, licenseKey: string): Promise<T> {
    const data = {
      license_key: licenseKey,
      app_server: appServer
    }

    return HttpService.post<T>(apiRoutes.activateLicenseOnline, data)
  },

  activateLicenseOffline<T>(appServer: string, licenseFile: File): Promise<T> {
    const formData = new FormData()
    formData.append('file', licenseFile)
    formData.append('app_server', appServer)

    return HttpService.post<T>(apiRoutes.activateLicenseOffline, formData)
  },

  deactivateLicense<T>(appServer: string): Promise<T> {
    return HttpService.delete<T>(apiRoutes.deactivateLicens + '?app_server=' + appServer)
  },

  getNews(): Promise<NewsItemType[]> {
    return HttpService.get<NewsItemType[]>(apiRoutes.getNews)
  },

  saveNewsItem<T>(data: NewsItemType): Promise<T> {
    return HttpService.post<T>(apiRoutes.saveNewsItem, data)
  },

  removeNewsItem<T>(data: NewsItemType): Promise<T> {
    return HttpService.delete<T>(apiRoutes.removeNewsItem + '?news_id=' + data.id)
  },
};
