import { AxiosResponse } from 'axios';
import { CustomError } from 'ts-custom-error'

export class HttpError extends CustomError {
  public constructor(
    public response: AxiosResponse,
    public code: number,
    message?: string,
  ) {
    super(message)
  }
}

export class ForbiddenError extends CustomError { }
export class NotFoundError extends CustomError { }
