import React, { useContext, useEffect } from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { ErrorsService } from 'app/modules/shared/services'
import { routeState } from 'app/modules/shared'
import { RouteState } from 'app/modules/shared/types'
import { AuthContext } from 'app/modules/auth'
import { AuthContextData } from 'app/modules/auth/context'
import { routeToRegexp } from 'app/modules/shared/routing/helpers'
import { useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap'

export function InternalErrorPage({error, resetError}: {error: Error, resetError?: () => void}): JSX.Element {
  const { _t } = useTypedTranslation()
  const pageState = useContext(routeState)
  const auth = useContext(AuthContext)
  const location = useLocation()

  useEffect(() => {
    logError(error, pageState, auth, location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const handleResetError = () => resetError ? resetError() : window.location.replace('/')

  return (
    <div className="container-fluid main-container">
      <div className="centered-layout error-page">
        <div className="error-code">500</div>
        <div className="error-message">{_t('internal-server-error')}</div>
        <div className="error-note text-muted text-center mb-4">
          {_t('error-note1')}<br/>
          {_t('error-note2')}<br/>
          {_t('error-note3')}<br/>
        </div>
        <Button variant="primary" onClick={handleResetError}>{_t('go-back')}</Button>
      </div>
    </div>
  )
}

async function logError(
  error: Error,
  pageState: RouteState,
  auth: AuthContextData,
  location: any
) {
  const url = location.pathname
  const data: {[key: string]: string | boolean} = {
    message: error?.toString() ?? '',
    url: url,
    is_signed_in: !!auth.user
  }

  if (typeof error?.stack !== 'undefined') {
    data.trace = error.stack
  }
  if (pageState.data.page?.key === 'report') {
    const urlParamsRegexp = routeToRegexp(pageState.data.page.url)
    const params = url.match(urlParamsRegexp)

    if (params && params[1]) {
      data.reportFlowId = params[1]
    }
    if (params && params[2]) {
      data.reportId = params[2]
    }
  }

  try {
    await ErrorsService.sendErrorData(data)
  } catch (e) {
    console.error(e)
  }
}
